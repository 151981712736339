<template>
  <div class="container">
    <b-modal id="images-ai-modal" ref="modal" title="Images AI" size="lg" classes="amc-modal">
      <template #body>
        <div class="mt-1 mb-1" style="display: flex">
          <input id="text" class="form-control" v-model="text" type="text" @keyup.enter="prompt()" placeholder="Enter prompt..." />
          <button type="button" id="chat-button" class="btn btn-accent ms-2" @click.stop="prompt()" :disabled="this.text.length == 0">
            <i class="material-icons" style="margin-right: 16px">send</i>Search
          </button>
        </div>
        <div class="image-container">
          <div class="spinner-border text-primary" role="status" v-if="spinner">
            <span class="visually-hidden">Loading image</span>
          </div>
          <img v-if="image" class="image" :src="image" />
          <div v-if="!image && !spinner" style="color: black">
            <h4>Tips for Great Images</h4>
            <ul>
              <li>
                <strong class="inline">Be Descriptive.</strong>
                <p class="inline">
                  Use more details, such as colors, textures, shapes, and sizes. For example, instead of saying "a dog", say "a fluffy, small, brown
                  Pomeranian".
                </p>
              </li>
              <li>
                <strong class="inline">Mood and Atmosphere.</strong>
                <p class="inline">
                  Describe the mood or atmosphere you want to convey. Words like “serene”, “chaotic”, “mystical”, or “futuristic” can guide the AI in
                  setting the right tone.
                </p>
              </li>
              <li>
                <strong class="inline">Different Art Styles.</strong>
                <p class="inline">
                  Experiment with a particular artist style, theme, or medium. For example, "cubism”, “art deco”, “minimalist”, "sketch drawing", or
                  "oil painting".
                </p>
              </li>
              <li>
                <strong class="inline">Lighting and Perspective.</strong>
                <p class="inline">
                  Mention if you want a close-up, a wide shot, a bird's-eye view, or a specific angle. Specifying the time of day can have a huge
                  impact on the result.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </template>

      <template #footer-right>
        <button type="button" class="btn btn-secondary m-2" @click="cancel()">Close</button>
        <button type="button" v-if="image" class="btn btn-accent m-2" @click="accept()">Use Image</button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import OpenAIMixin from '../mixins/OpenAIMixin';
import axios from 'axios';
import BModal from '@/components/common/BModal.vue';

export default {
  name: 'ImagesAIModal',
  components: { BModal },
  mixins: [OpenAIMixin],
  props: ['bucket'],
  data() {
    return {
      text: '',
      image: null,
      spinner: false,
    };
  },
  methods: {
    async prompt() {
      if (this.text.length == 0) return;

      this.image = null;
      const response = await this.promptDallE(this.text, 'dall-e-3', '1024x1024', 'standard', (val) => (this.spinner = val));
      this.image = this.getImage(response);
    },
    cancel() {
      this.text = '';
      this.response = null;
      this.$refs.modal.hide();
    },
    async accept() {
      const item = {};
      this.$emit('show-spinner', true);
      this.$emit('set-attach');
      this.$refs.modal.hide();

      //this.croppieAttachment = this.image;

      console.log('downloading', item);
      const url = `${this.$hostname}/api/copyImage`;
      console.log(url);
      const response = await axios.post(url, {
        url: this.image,
        communityKey: this.communityKey || this.getCampgroundKey,
        bucket: this.bucket,
      });
      console.log('response', response);

      item.newDrawableUrl = response.data.url;
      item.name = this.getPhotoFileNameFromUrl(item.newDrawableUrl);

      console.log('item details', item);

      this.$emit('show-spinner', false);
      this.$emit('ai-image', item);
    },
    getPhotoFileNameFromUrl(url) {
      const urls = url.split('/');
      const tmpFileName = urls[urls.length - 1];
      let filename = tmpFileName.split('?')[0];

      if (filename.indexOf('.jpg') === -1 && filename.indexOf('.jpeg') === -1) {
        if (filename.endsWith('.')) {
          filename = filename.substring(0, filename.length - 2);
        }
        filename += '.jpg';
      }

      return filename;
    },
    showModal() {
      this.$refs.modal.show();
      this.isShowing = !this.isShowing;
    },
  },
};
</script>

<style scoped>
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 0px solid white;
  border-radius: 8px;
  padding: 16px;
}
.image {
  width: 80%;
  height: auto;
}
.inline {
  display: inline;
}
li {
  margin-top: 10px;
}

#chat-button {
  height: 38px;
  width: 145px;
}
</style>
