<template>
  <header>
    <div v-if="isNotRemote" id="header-buttons">
      <button id="logout" class="header-button" @click.stop="logout"><span>Log Out</span></button>
      <!--i class="material-icons header-button" v-b-toggle.notifications>notifications </i-->
    </div>
    <!--b-collapse v-if="isNotRemote" id="notifications" class="mt-2">
      <b-card title="Notifications" bg-variant="primary" columns style="overflow:auto; height:400px">
        <b-card class="m-2" v-for="item in notifications" bg-variant="secondary" :key="item._id" :title="item.title">
          {{ item.description }}
          <b-button v-if="item.url" :href="item.url" variant="primary">Open</b-button>
        </b-card>
      </b-card>
    </b-collapse-->
    <h2>{{ getCampgroundName }}</h2>
  </header>
</template>

<script>
//import axios from 'axios';
export default {
  name: 'Header',
  data() {
    return {
      notifications: null,
    };
  },
  created() {
    /*let url = `${this.$hostname}/api/notifications`;
    axios.get(url).then((response) => {
      this.notifications = response.data || [];
    });*/
  },
  computed: {
    isNotRemote: function () {
      return !this.$route.meta.isRemote;
    },
  },
  methods: {
    logout: function () {
      window.location.href = '/login?action=logout';
    },
  },
};
</script>

<style scoped>
header {
  background-color: #111;
  padding: 10px;
  color: white;
}
h1 {
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
i,
i:active,
i:visited {
  color: white;
  font-size: 30px;
  line-height: 50px;
  float: right;
}

#header-buttons {
  position: absolute;
  right: 0px;
  top: 0px;
  margin: 10px;
  height: 50px;
  z-index: 999997;
}
#notifications {
  position: absolute;
  right: 0px;
  top: 60px;
  margin: 10px;
  height: 50px;
  z-index: 999997;
}

#header-buttons i.material-icons {
  font-size: 22px;
  line-height: normal;
}
.header-button {
  border-radius: 999rem;
  box-shadow: none;
  background-color: #01f2a6 !important;
  color: #0e6046 !important;
  fill: #0e6046 !important;
  bottom: 0;
  padding: 13px 22px;
  letter-spacing: 0.6;
  border: none !important;
  font-weight: 700;
  margin: 0rem 0.25rem;
}

#logout {
  font-size: 15px;
}

#logout span {
  height: 20px;
  line-height: 20px;
}
</style>
