import { createApp, toRaw } from 'vue';
import App from './App.vue';
import router from './router';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import store from './store/store';
import axios from 'axios';

//Import mixins
import { locationData } from './components/mixins/LocationData.js';
import { ExternalPhotosMixin } from './components/mixins/ExternalPhotosMixin';

//Import modules
//import BootstrapVue from 'bootstrap-vue';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import { CoolSelectPlugin } from 'vue-cool-select';
import 'vue-cool-select/dist/themes/bootstrap.css';
import { VuelidatePlugin } from '@vuelidate/core';
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';

//Bootstrap
import 'bootstrap';

//Shoelace
import '@shoelace-style/shoelace/dist/themes/light.css';
import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path';
setBasePath('https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.15.0/cdn/');

//Import plugins
import { Toast } from './plugins/toast.js';
import { Message } from './plugins/message.js';
import { CopyText } from './plugins/copyText.js';

//Global components
import Validation from '@/components/common/Validation';

import { registerIconLibrary } from '@shoelace-style/shoelace/dist/utilities/icon-library.js';
//Register Material Icons for use with Shoelace Icons (sl-icon)
registerIconLibrary('material', {
  resolver: (name) => {
    const match = name.match(/^(.*?)(_(round|sharp))?$/);
    return `https://cdn.jsdelivr.net/npm/@material-icons/svg@1.0.5/svg/${match[1]}/${match[3] || 'outline'}.svg`;
  },
  mutator: (svg) => svg.setAttribute('fill', 'currentColor'),
});

/*const domain = window.location.hostname;
//if(domain.startsWith('127.0.0.1')){
//    domain = req.headers['x-forwarded-host'];
//}
var subDomains = domain.split('.');
var fbProject = 'resort-navigator';
if(subDomains.length > 2 || (subDomains.length == 2 && subDomains[1] == 'localhost:3000')){
    [fbProject] = subDomains;
}
*/

import { createTooltipDirective, clickOutsideDirective } from './directives.js';
const tooltipTopDirective = createTooltipDirective('top');
const tooltipRightDirective = createTooltipDirective('right');
const tooltipBottomDirective = createTooltipDirective('bottom');
const tooltipLeftDirective = createTooltipDirective('left');

const subDomains = window.location.hostname.split('.');
const fbProject = subDomains.length > 2 || (subDomains.length == 2 && subDomains[1] == 'localhost:3000') ? subDomains[0] : 'resort-navigator';

try {
  axios.get(`/accounts/${fbProject}.json`).then((config) => {
    firebase.initializeApp(config.data);
    let app = '';
    firebase.auth().onAuthStateChanged(() => {
      if (!app) {
        app = createApp(App);

        app.config.globalProperties.$vueHost = `${window.location.protocol}//${window.location.host}`;
        if (process.env.NODE_ENV == 'development') {
          app.config.globalProperties.$hostname = `${location.protocol}//${location.hostname}:3000`;
          app.config.globalProperties.$connectClient = 'ca_GIMIcf40xPrpZbmU37Bxc60gFVxhDIPY';
        } else {
          app.config.globalProperties.$hostname = `${location.protocol}//${location.hostname}`;
          app.config.globalProperties.$connectClient = 'ca_GIMImdadRY4NtIkKd5fPpotokpT51vdQ';
        }

        app.config.globalProperties.$fbProject = fbProject;
        app.config.globalProperties.$fbWebApiKey = config.data.apiKey;
        app.config.globalProperties.$raw = toRaw;
        app.config.globalProperties.$toast = Toast;
        app.config.globalProperties.$message = Message;
        app.config.globalProperties.$copyText = (text) => CopyText.copyText(text);

        //Global mixins
        app.mixin(locationData);
        app.mixin(ExternalPhotosMixin);

        //Global directives
        app.directive('click-outside', clickOutsideDirective);
        app.directive('tooltip-top', tooltipTopDirective);
        app.directive('tooltip-right', tooltipRightDirective);
        app.directive('tooltip-bottom', tooltipBottomDirective);
        app.directive('tooltip-left', tooltipLeftDirective);

        app.use(store);
        app.use(router);

        //Global components
        app.use(CoolSelectPlugin);
        app.use(VuelidatePlugin);
        app.component('Validation', Validation);
        app.component('EasyDataTable', Vue3EasyDataTable);

        app.mount('#app');
      }
    });
  });
} catch (e) {
  console.log(e);
}
