export const BlurImageMixin = {
  methods: {
    //Returns the file as an processed (blurred) image blob, or the file parameter.
    async retrieveImage(file) {
      return await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = async (event) => {
          const image = new Image();
          image.src = event.target.result;

          image.onload = () => {
            const blurredImage = this.blurImage(image);
            if (blurredImage) {
              resolve(blurredImage);
            } else {
              resolve(null);
            }
          };
        };
        reader.readAsDataURL(file);
      });
    },
    //Same as above, but simply skips the FileReader stage.
    async retrieveImage2(url) {
      return await new Promise((resolve) => {
        const image = new Image();
        image.src = url;
        image.crossOrigin = 'Anonymous';

        image.onload = () => {
          const blurredImage = this.blurImage(image);
          if (blurredImage) {
            resolve(blurredImage);
          } else {
            resolve(null);
          }
        };
      });
    },
    //Converts the canvas to a blob. Used specifically by retrieveImage.
    canvasToBlob(canvas) {
      return new Promise((resolve) => {
        canvas.toBlob((blob) => {
          resolve(blob);
        });
      });
    },
    //converts a data URL to a blob.
    dataToBlob(dataURL) {
      const arr = dataURL.split(',');
      const mimeMatch = arr[0].match(/:(.*?);/);
      const mime = mimeMatch && mimeMatch[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },

    //Calculates aspect ratio (AR), passes parameters depending on AR, returns the processed image (or null if not applicable).
    blurImage(image, maxWidth = 1125, maxHeight = 600) {
      const aspectRatio = image.width / image.height;
      const targetAR = maxWidth / maxHeight;
      const differencePct = 1 - aspectRatio / targetAR;

      console.log(aspectRatio, targetAR, differencePct, image.width, image.height);

      //Processes image if AR difference is > 10%.
      if (differencePct > 0.1) {
        return this.processImage(image, true);
      } else if (differencePct < -0.1) {
        return this.processImage(image, false);
      } else {
        return null;
      }
    },
    //Position/scale calculation, blurring, and layering.
    processImage(image, isVertical, maxWidth = 1125, maxHeight = 600, boxBlur = 25, gaussBlur = 20) {
      //Scale canvas height so that it only has to blur a small image.
      const scaledHeight = isVertical === true ? Math.round((maxWidth / image.width) * image.height) : maxHeight;
      const scaledwidth = isVertical === false ? Math.round((maxHeight / image.height) * image.width) : maxWidth;

      //Create image canvases and contexts.
      const { canvas: background, context: backgroundContext } = this.createCanvas(maxWidth, maxHeight);
      const { canvas: foreground, context: foregroundContext } = this.createCanvas(maxWidth, maxHeight);

      //1. Blur image.
      const blurY = isVertical === true ? -(maxWidth / (image.width / image.height) - maxHeight) / 2 : 0;
      const blurX = isVertical === false ? -(maxHeight / (image.height / image.width) - maxWidth) / 2 : 0;
      backgroundContext.drawImage(image, blurX, blurY, scaledwidth, scaledHeight);
      //One pass of both box and gaussian blurs for best results and performance.
      const pass1 = this.applyBoxBlur(backgroundContext.getImageData(0, 0, background.width, background.height), boxBlur);
      const pass2 = this.applyGaussianBlur(pass1, gaussBlur);
      backgroundContext.putImageData(pass2, 0, 0);

      //2. Scale original image to foreground, maintaining aspect ratio.
      foregroundContext.clearRect(0, 0, foreground.width, foreground.height);
      //Vertical only cares about x position and width.
      const x = isVertical === true ? (maxWidth - (image.width / image.height) * maxHeight) / 2 : 0;
      const width = isVertical === true ? (image.width / image.height) * maxHeight : maxWidth;
      //Horizontal only cares about y position and height.
      const y = isVertical === false ? (maxHeight - (image.height / image.width) * maxWidth) / 2 : 0;
      const height = isVertical === false ? (image.height / image.width) * maxWidth : maxHeight;
      foregroundContext.drawImage(image, x, y, width, height);

      //4. Layer images and return result.
      backgroundContext.drawImage(foreground, 0, 0);
      return background.toDataURL();
    },
    //Canvas initialization.
    createCanvas(width, height) {
      const canvas = document.createElement('canvas');
      canvas.setAttribute('width', width);
      canvas.setAttribute('height', height);
      const context = canvas.getContext('2d');
      return { canvas, context };
    },

    //Blur functions.
    applyBoxBlur(imageData, blur) {
      const data = imageData.data;
      const width = imageData.width;
      const height = imageData.height;
      const halfBlur = Math.floor(blur / 2);

      function getPixelIndex(x, y) {
        return (y * width + x) * 4;
      }

      function getAverageColor(x, y) {
        let r = 0,
          g = 0,
          b = 0,
          a = 0;
        for (let offsetY = -halfBlur; offsetY <= halfBlur; offsetY++) {
          for (let offsetX = -halfBlur; offsetX <= halfBlur; offsetX++) {
            const pixelIndex = getPixelIndex(Math.min(width - 1, Math.max(0, x + offsetX)), Math.min(height - 1, Math.max(0, y + offsetY)));

            r += data[pixelIndex];
            g += data[pixelIndex + 1];
            b += data[pixelIndex + 2];
            a += data[pixelIndex + 3];
          }
        }
        const numPixels = blur * blur;
        return [Math.floor(r / numPixels), Math.floor(g / numPixels), Math.floor(b / numPixels), Math.floor(a / numPixels)];
      }

      const newData = new Uint8ClampedArray(data.length);

      for (let y = 0; y < height; y++) {
        for (let x = 0; x < width; x++) {
          const pixelIndex = getPixelIndex(x, y);
          const [r, g, b, a] = getAverageColor(x, y);
          newData[pixelIndex] = r;
          newData[pixelIndex + 1] = g;
          newData[pixelIndex + 2] = b;
          newData[pixelIndex + 3] = a;
        }
      }

      return new ImageData(newData, width, height);
    },
    applyGaussianBlur(imageData, blurRadius) {
      const data = imageData.data;
      const width = imageData.width;
      const height = imageData.height;
      const radius = Math.floor(blurRadius) || 1;
      const diameter = radius * 2 + 1;
      const radiusSquared = radius * radius;
      const kernel = new Array(diameter);

      function getPixelIndex(x, y) {
        return (y * width + x) * 4;
      }

      function generateGaussianKernel() {
        let sum = 0;
        for (let i = 0; i < diameter; i++) {
          const x = i - radius;
          const value = Math.exp(-(x * x) / (2 * radiusSquared));
          kernel[i] = value;
          sum += value;
        }
        // Normalize the kernel
        for (let i = 0; i < diameter; i++) {
          kernel[i] /= sum;
        }
      }

      function applyHorizontalBlur() {
        for (let y = 0; y < height; y++) {
          for (let x = 0; x < width; x++) {
            let sumR = 0,
              sumG = 0,
              sumB = 0,
              sumA = 0;
            for (let i = 0; i < diameter; i++) {
              const offsetX = x + i - radius;
              const clampedX = Math.min(width - 1, Math.max(0, offsetX));
              const pixelIndex = getPixelIndex(clampedX, y);
              const weight = kernel[i];
              sumR += data[pixelIndex] * weight;
              sumG += data[pixelIndex + 1] * weight;
              sumB += data[pixelIndex + 2] * weight;
              sumA += data[pixelIndex + 3] * weight;
            }

            const pixelIndex = getPixelIndex(x, y);
            data[pixelIndex] = Math.round(sumR);
            data[pixelIndex + 1] = Math.round(sumG);
            data[pixelIndex + 2] = Math.round(sumB);
            data[pixelIndex + 3] = Math.round(sumA);
          }
        }
      }

      function applyVerticalBlur() {
        for (let x = 0; x < width; x++) {
          for (let y = 0; y < height; y++) {
            let sumR = 0,
              sumG = 0,
              sumB = 0,
              sumA = 0;
            for (let i = 0; i < diameter; i++) {
              const offsetY = y + i - radius;
              const clampedY = Math.min(height - 1, Math.max(0, offsetY));
              const pixelIndex = getPixelIndex(x, clampedY);
              const weight = kernel[i];
              sumR += data[pixelIndex] * weight;
              sumG += data[pixelIndex + 1] * weight;
              sumB += data[pixelIndex + 2] * weight;
              sumA += data[pixelIndex + 3] * weight;
            }

            const pixelIndex = getPixelIndex(x, y);
            data[pixelIndex] = Math.round(sumR);
            data[pixelIndex + 1] = Math.round(sumG);
            data[pixelIndex + 2] = Math.round(sumB);
            data[pixelIndex + 3] = Math.round(sumA);
          }
        }
      }

      generateGaussianKernel();
      applyHorizontalBlur();
      applyVerticalBlur();

      return imageData;
    },
  },
};
