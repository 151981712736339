<template>
  <div class="two-column">
    <div id="center-column" @scroll="onScroll">
      <div v-if="tabs" id="tabs">
        <div
          v-for="(tab, index) in tabs"
          v-bind:key="index"
          :class="{ tab: true, selected: index == tabIndex }"
          :style="getTabStyle(index == tabIndex)"
          @click="setIndex(index)">
          {{ tab.title }}
        </div>
      </div>
      <div id="item-header" :style="getHeaderStyles()">
        <iframe
          v-if="headerInfo.videoURL"
          width="490"
          height="261"
          :src="`https://www.youtube.com/embed/${headerInfo.videoURL}`"
          title="YouTube video player"
          frameborder="0"
          allow="mute; autoplay; allowfullscreen"></iframe>
        <div
          v-if="headerInfo.images && headerInfo.images.length > 0"
          id="listBuilderCarousel"
          class="carousel slide"
          data-bs-ride="carousel"
          :data-bs-interval="headerInfo.delaySeconds * 1000 || 5000">
          <div class="carousel-inner">
            <div class="carousel-item" v-for="(src, index) in this.headerInfo.images" :class="{ active: index === 0 }" :key="index">
              <img :src="src.imageURL" class="d-block w-100" :alt="'Slide ' + (index + 1)" />
              <div class="carousel-caption d-none d-md-block">
                <p>{{ src.caption }}</p>
              </div>
            </div>
          </div>
          <ol class="carousel-indicators" style="list-style-type: none">
            <li
              v-for="(src, index) in headerInfo.images"
              :key="index"
              data-bs-target="#listBuilderCarousel"
              :data-bs-slide-to="index"
              :class="{ active: index === 0 }" />
          </ol>
          <button
            v-if="headerInfo.images && headerInfo.images.length > 1"
            id="carousel-control-prev"
            class="carousel-control-prev"
            type="button"
            data-bs-target="#listBuilderCarousel"
            data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            v-if="headerInfo.images && headerInfo.images.length > 1"
            id="carousel-control-next"
            class="carousel-control-next"
            type="button"
            data-bs-target="#listBuilderCarousel"
            data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
        <h1 v-show="menuVisibility">{{ menuTitle }}</h1>
      </div>

      <ul
        :id="displayType == 'categorygrid' ? 'grid-container' : 'items'"
        :class="{ 'hide-actions': action }"
        v-for="(element, index) in chunkKeys"
        :key="index">
        <li :class="displayType == 'categorygrid' ? '' : 'item'">
          <component
            :is="centerComponent"
            :item="chunkList[index]"
            :prevItemId="chunkKeys[index - 1]"
            :locations="locations"
            :listIcons="listIcons"
            :itemId="element"
            :dbContext="dbContext"
            :displayType="displayType"
            @deleteItem="deleteItem"
            @action="setAction"
            @editItem="editItem"
            @updateItem="updateItem"
            :selectedItem="getSelectedItem" />
        </li>
      </ul>
    </div>
    <div id="right-column" class="p-2">
      <component
        :is="rightColumnComponent"
        v-bind="rightColumnProps"
        @action="setAction"
        :key="rightColumnKey"
        :rightColumnKey="rightColumnKey"
        :action="action"
        @header-info-update="headerInfoUpdate" />
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import InfiniteScrollMixin from '@/components/mixins/InfiniteScrollMixin.js';
import { typeMap, newObjectMap } from '@/json/listBuilderData.json';
import ButtonNavigation from '@/components/ButtonNavigation.vue';

import HeaderImages from '@/components/forms/HeaderImages.vue';

import SimpleItems from '@/components/listitems/SimpleItems.vue';
import Notification from '@/components/forms/Notification.vue';

export default {
  components: {
    ButtonNavigation,
    HeaderImages,
    SimpleItems,
    Notification,
  },
  mixins: [InfiniteScrollMixin],
  //   watch: {
  //     '$route.params': {
  //       async handler(newVal, oldVal) {
  //         if (newVal && oldVal && newVal.gridMenuKey !== oldVal.gridMenuKey) {
  //           this.$router.go();
  //         }
  //       },
  //     },
  //   },
  data() {
    return {
      headerInfo: {},
      menuTitle: '',
      menuVisibility: true,
      type: null,
      locations: [],
      listIcons: [],
      tabIndex: 0,
    };
  },
  async created() {
    this.chunkSize = 30;
    this.setRouteProperties();

    this.addActions();
    await this.getLocations();
  },
  async mounted() {
    await this.resetData();
    if (this.$route.params?.action == 'add') this.setAction('add-button');
    if (!this.action) await this.setAction();

    //Fix for bootstrap not automatically starting carousel.
    setTimeout(() => {
      if (this.headerInfo.images && this.headerInfo.images.length > 1) {
        const prev = document.getElementById('carousel-control-prev');
        const next = document.getElementById('carousel-control-next');
        next?.click();
        prev?.click();
      }
    }, 1000);
  },
  methods: {
    addActions() {
      this.addAction('submit', () => {
        this.action = null;
        this.clearChunks();
        this.resetData();
        this.actions['default']();
      });
      this.addAction('cancel', () => {
        this.action = null;
        if (this.rightColumnItem) {
          this.rightColumnItem = {};
          this.deleteItem(this.rightColumnKey);
        }
        this.actions['default']();
      });
      this.addAction('edit', () => {
        this.rightColumnItem = this.getItemByKey(this.rightColumnKey);
        this.rightColumnProps = {
          dbContext: this.dbContext,
          item: this.rightColumnItem,
          rightColumnKey: this.rightColumnKey || '',
          action: this.action,
        };

        if (newObjectMap[this.displayType] && newObjectMap[this.displayType].detailComponent) {
          this.rightColumnComponent = newObjectMap[this.displayType].detailComponent;
          console.log('rightColumnComponent', this.rightColumnComponent);
        }
      });
      this.addAction('duplicate', () => {
        const duplicateKey = this.params[1];
        let newObject = { ...this.getItemByKey(duplicateKey) };

        this.$delete(newObject, 'effectiveTime');
        this.$delete(newObject, 'endTime');
        this.$delete(newObject, 'deliveryTime');
        this.$delete(newObject, 'recurrenceId');
        this.$delete(newObject, 'recurrenceInfo');

        this.rightColumnKey = this.databasePath.push().key;
        this.chunkKeys.unshift(this.rightColumnKey);
        this.chunkList.unshift(newObject);
        console.log('chunk data', this.chunkList, this.chunkKeys);

        //Fallthrough. Loads the object into the right column for editing.
        this.setAction('edit');
        this.$nextTick(() => {
          this.action = 'add-button';
        });
      });
      this.addAction('add-button', () => {
        if (newObjectMap[this.displayType] && newObjectMap[this.displayType].newObject) {
          this.rightColumnItem = JSON.parse(JSON.stringify(newObjectMap[this.displayType].newObject));
        }

        this.rightColumnKey = this.databasePath.push().key;
        this.chunkKeys.unshift(this.rightColumnKey);
        this.chunkList.unshift(this.rightColumnItem);

        this.actions['edit']();
        console.log('item chunkList', this.chunkList[this.rightColumnKey], this.chunkList, this.chunkKeys);
      });

      this.addAction('header-images', () => {
        this.rightColumnProps = {
          section: 'actions',
          context: this.action,
          dbContext: this.headerContextMap[this.dbContext] || this.dbContext,
          visible: true,
          displayType: 'HeaderImages',
          tabs: this.tabs,
          title: this.menuTitle,
        };
        this.rightColumnComponent = 'HeaderImages';
        this.action = null;
      });

      this.addAction('view-archived', async () => {
        this.changeView('view-archived');

        if (this.isSystemwide) {
          this.campgroundKey = 'system-data';
          this.locationDataRef = this.getLocationRef('system-data');
        }

        await this.nextChunk();
      });
      this.addAction('view-active', async () => {
        this.changeView('view-active');

        if (this.isSystemwide) {
          this.campgroundKey = 'system-data';
          this.locationDataRef = this.getLocationRef('system-data');
          this.setDatabasePath(this.getLocationRef('system-data'), 'messages');
        } else {
          if (this.dbContext == 'messages-scheduled') {
            this.dbContext = 'messages';
            this.setDatabasePath(this.getLocationRef(), 'messages');
          }
        }

        await this.nextChunk();
      });
      this.addAction('scheduled-notifications', async () => {
        this.changeView('scheduled-notifications');

        if (this.isSystemwide) {
          this.campgroundKey = 'system-data';
          this.locationDataRef = this.getLocationRef('system-data');
          this.setDatabasePath(this.getLocationRef('system-data'), 'messages-scheduled');
        } else {
          this.setDatabasePath(this.getLocationRef(), 'messages-scheduled');
        }
        // if (this.$route && this.$route.params) {
        //   this.setDatabasePath(
        //     this.getLocationRef(),
        //     this.$route.params.context === 'send-detail-notification' ? 'remote-messages-scheduled' : this.dbContext
        //   );
        //   if (this.isSystemwide) {
        //     this.campgroundKey = 'system-data';
        //     this.locationDataRef = this.getLocationRef('system-data');
        //   }
        // }

        await this.nextChunk();
      });
    },
    chunkQuery(query) {
      if (this.type == 'messages') {
        if (this.lastLoadedKey) query = query.orderByKey().endAt(String(this.lastLoadedKey));
        query = query.limitToLast(this.chunkSize);
      }
      return query;
    },
    getDataArray(data) {
      if (this.type == 'messages') {
        //return viewArchived == true ? endTime !== 0 && endTime < currentTime : endTime >= currentTime || endTime == 0;
        return Object.values(data).reverse();
      }

      return Object.values(data);
    },
    getKeyArray(data) {
      if (this.type == 'messages') {
        //if (this.dbContext !== 'messages-scheduled') {
        return Object.keys(data).reverse();
      }
      return Object.keys(data);
    },
    async onDataPush() {
      if (this.type == 'messages' && this.dbContext !== 'messages-scheduled') {
        const currentTime = Date.now();
        this.chunkKeys = this.chunkKeys.filter((key) => {
          const index = this.chunkKeys.indexOf(key);
          const val = this.chunkList[index];
          const result =
            this.view == 'view-archived' ? val.endTime !== 0 && val.endTime < currentTime : val.endTime >= currentTime || val.endTime == 0;
          return result;
        });
        this.chunkList = this.chunkList.filter((item) =>
          this.view == 'view-archived' ? item.endTime !== 0 && item.endTime < currentTime : item.endTime >= currentTime || item.endTime == 0
        );
      }
    },

    async getLocations() {
      this.campgroundKey = this.getCampgroundKey;
      //this.locationDataRef = this.getLocationRef(this.campgroundKey);
      this.locations = (await this.locationDataRef.child('locations').once('value')).val() || {};
    },
    headerInfoUpdate(item) {
      this.headerInfo = item;
      this.menuVisibility = !item.hideTitle;
      console.log('headerInfoUpdate', item);
    },
    getTabStyle: function (selected) {
      return selected ? `border-bottom-color: ${this.getAccentColor};${this.getPrimaryColorStyle}` : this.getPrimaryColorStyle;
    },
    getHeaderStyles: function () {
      const image =
        this.headerInfo && this.headerInfo.images && this.headerInfo.images.length > 0 ? this.headerInfo.images[0].imageURL || null : null;
      if (image) {
        return `height:261px;background-image:url("${image}");`;
      } else if (this.headerInfo.videoURL) {
        return `height:261px;`;
      }
      return `${this.getBackgroundColorStyle}height:50px;`;
    },

    async resetData() {
      if (this.context) {
        console.log('resetData() this.$route.params', this.$route.params);

        if (this.isSystemwide) {
          this.setDatabasePath(await this.getLocationRef('system-data'), 'messages');
          this.menuTitle = 'Systemwide Notification';
          this.type = 'messages';
          this.emptyComponent = typeMap[this.type][this.tabIndex].emptyComponent;
          this.emptyMessage = typeMap[this.type][this.tabIndex].emptyMessage;
          this.displayType = typeMap[this.type][this.tabIndex].displayType;
        } else {
          await this.setMenuItemData();
          await this.setMixinProperties();
          await this.getHeaderInfo();
        }

        await this.setAction();
        await this.nextChunk();
      }
    },
    async setMenuItemData() {
      const menuItem = await this.getMenuData(this.context);
      this.menuItem = menuItem;
      console.log('setMenuItemData() menuItem', this.menuItem);

      if (this.menuItem && this.menuItem.tabs) {
        this.tabs = this.menuItem.tabs;
        this.currentTab = this.tabs.find((tab) => tab.id == this.$route.params.tab);

        console.log('setMenuItemData() tabs/currentTab', this.tabs, this.currentTab);
      }
    },
    async getMenuData(context) {
      console.log('getMenuData() context', context);

      const snapshot = await firebase
        .database()
        .ref('resort-navigator')
        .child('location-data')
        .child(this.getCampgroundKey)
        .child('main-menu')
        .orderByChild('uniqueId')
        .equalTo(context)
        .once('value');

      let result;
      snapshot.forEach((child) => {
        let value = child.val();
        if (value) {
          result = child.val();
          return;
        }
      });
      console.log('getMenuData() mainMenu', result);

      return result;
    },
    async setMixinProperties() {
      this.setDatabasePath(await this.getLocationRef(this.campgroundKey), this.menuItem.type ? this.menuItem.uniqueId : this.menuItem.id);
      console.log('context 1', this.context);

      this.context = this.menuItem.type ? this.menuItem.type : this.menuItem.id;
      console.log('context 2', this.context);

      this.type = this.context;

      this.chunkSize = 15;

      this.menuTitle = this.menuItem?.name;
      this.tabIndex = this.tabs?.indexOf(this.currentTab) || 0;
      this.emptyComponent = typeMap[this.type][this.tabIndex].emptyComponent;
      this.emptyMessage = typeMap[this.type][this.tabIndex].emptyMessage;
      this.displayType = typeMap[this.type][this.tabIndex].displayType;

      if (this.tabs) {
        this.setDatabasePath(this.getLocationRef(), `${this.dbContext}-${this.tabs[this.tabIndex].id}`);
      }
    },

    async getHeaderInfo() {
      this.headerInfo =
        (await this.getContextRef(`header-info/${this.headerContextMap[this.dbContext] || this.dbContext}`).once('value')).val() || [];
      this.menuVisibility = !this.headerInfo.hideTitle;
    },
    setIndex(index) {
      const toAppend = this.$route.name == 'scroller';
      this.$router.push({ path: this.tabs[index].id, append: toAppend });

      this.clearChunks();
      this.resetData();
      this.tabIndex = index;
    },
  },
};
</script>
