import moment from 'moment-timezone';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/functions';
import { mappedIconsHanddrawn, mappedIcons, colorPalette } from '../../json/mappedIcons.json';

import useVuelidate from '@vuelidate/core';
import axios from 'axios';

export const locationData = {
  campgroundMap: {
    CAMPGROUND_NAME: 'name',
    CAMPGROUND_APP_NAME: 'appName',
    CAMPGROUND_DRAWABLE: 'drawable',
    CAMPGROUND_TIMEZONE: 'timeZone',
    CAMPGROUND_LATITUDE: 'latitude',
    CAMPGROUND_LONGITUDE: 'longitude',
    CAMPGROUND_PHONE: 'phone',
    CAMPGROUND_ICON_SET: 'iconSet',
    CAMPGROUND_COLOR_ACCENT: 'colorAccent',
    CAMPGROUND_COLOR_BACKGROUND: 'colorBackground',
    CAMPGROUND_COLOR_PRIMARY: 'colorPrimary',
    CAMPGROUND_COLOR_HIGHLIGHTEDTEXT: 'colorHighlightedText',
    CAMPGROUND_COLOR_ICON_BACKGROUND: 'colorIconBackground',
    CAMPGROUND_COLOR_ICON_SHAPE: 'colorIconShape',
    CAMPGROUND_COLOR_ICON_BACKGROUND_RANDOM: 'colorIconBackgroundRandom',
    CAMPGROUND_COLOR_ICON_SHAPE_RANDOM: 'colorIconShapeRandom',
    CAMPGROUND_TITLE: 'title',
    CAMPGROUND_IS_BUSINESS_ONLY: 'isBusinessOnly',
    CAMPGROUND_DISPLAY_NAME: 'displayName',
  },
  data() {
    return {
      iconPrefixMapping: {
        solid: 'fas',
        regular: 'far',
        light: 'fal',
        duotone: 'fad',
        custom: 'fac',
      },
      vuelidate$: useVuelidate({ $stopPropagation: true }),
    };
  },
  methods: {
    jsonToQueryParams(json) {
      return Object.keys(json)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`)
        .join('&');
    },
    base64urlEncode(str) {
      // Make Base64 URL-safe by replacing '+' with '-', '/' with '_', and removing '='
      return btoa(str).replace(/\+/g, '-').replace(/\//g, '_');
    },

    base64urlDecode(str) {
      // Convert URL-safe Base64 to regular Base64 by replacing '-' with '+', '_' with '/'
      // Decode Base64 string
      return atob(str.replace(/-/g, '+').replace(/_/g, '/'));
    },
    async createShortLink(url, queryParams) {
      const shortURLref = this.getContextRef('config/shortURLs').child(this.base64urlEncode(url));
      const savedURL = (await shortURLref.once('value')).val();
      if (savedURL) return savedURL;
      const options = {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          Authorization: 'pk_Z3ZAZGsh41Kpx6OM',
        },
        body: JSON.stringify({
          domain: 'go.appmycommunity.com',
          originalURL: url,
          allowDuplicates: false,
          tags: [this.$fbProject, queryParams.communityKey, queryParams.action, queryParams.businessTitle],
        }),
      };
      try {
        const shortURLData = await fetch('https://api.short.io/links/public', options);
        const shortURLJSON = await shortURLData.json();
        shortURLref.set(shortURLJSON.shortURL);
        return shortURLJSON.shortURL;
      } catch (err) {
        console.error(err);
        return url;
      }
    },
    async getAdjustToken() {
      const appList = await this.getAppList();
      const appId = appList[0].value;
      return (await firebase.database().ref('resort-navigator').child('app-data').child(appId).child('adjustToken').once('value')).val();
    },
    async createAdjustDeepLink(action, actionKey, params, skipSplashScreen, communityKey) {
      const adjustToken = await this.getAdjustToken();
      if (!adjustToken) return null;
      const actionParams = (params || []).reduce((acc, param) => {
        acc[param.key] = param.value;
        return acc;
      }, {});
      const queryParams = {
        ...actionParams,
        adj_t: adjustToken,
        communityKey: communityKey || this.getCampgroundKey,
        action,
        actionKey,
        skipSplashScreen,
      };
      const fbProject = this.$fbProject.replaceAll('-', '');
      const finalURL = `http://${fbProject}.go.link/deeplinks?${this.jsonToQueryParams(queryParams)}`;
      console.log('🚀 ~ createAdjustDeepLink ~ finalURL:', finalURL);
      // Stopped using shortner because Apple will open the app and the store.
      //return await this.createShortLink(finalURL, queryParams);
      return finalURL;
    },
    async createDeepLink(url, id, params, isHosted, communityKey) {
      communityKey = communityKey || this.getCampgroundKey;

      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'text/plain');
      const packageId = this.$fbProject.replaceAll('-', '');
      const linkDomain = isHosted ? `https://${this.$fbProject}.web.app` : 'https://www.appmycommunity.com';
      const link = (params || []).reduce(
        (acc, param) => `${acc}${param.key}=${encodeURIComponent(param.value)}&`,
        `${linkDomain}/${url}/${id}?communityKey=${encodeURIComponent(communityKey)}&`
      );

      const packageNameData = JSON.parse(window.localStorage.getItem('CAMPGROUND_PACKAGE_NAMES'));
      const requestData = {
        dynamicLinkInfo: {
          domainUriPrefix: `https://${packageId}.page.link`,
          link,
          androidInfo: {
            androidPackageName: `${packageNameData.android}`,
          },
          iosInfo: {
            iosBundleId: `${packageNameData.ios}`,
            iosAppStoreId: `${packageNameData.iosId}`,
          },
        },
        suffix: {
          option: 'SHORT',
        },
      };
      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(requestData),
        redirect: 'follow',
      };
      const response = await fetch(`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${this.$fbWebApiKey}`, requestOptions);
      return (await response.json()).shortLink;
    },
    setCampgroundLocationData: async function (locationId, campgroundLocation) {
      console.log('locationId', locationId);
      if (!campgroundLocation) {
        campgroundLocation = (await firebase.database().ref('resort-navigator').child('campground-locations').child(locationId).once('value')).val();
      }
      this.$store.commit('updateCommunity', campgroundLocation);
      window.localStorage.setItem('CAMPGROUND_KEY', locationId);
      let packageNames = campgroundLocation.packageNames;
      if (!packageNames) {
        const sharedPackageNames = (
          await firebase.database().ref('resort-navigator').child('location-data/system-data/config/packageNames').once('value')
        ).val();
        if (sharedPackageNames) {
          packageNames = sharedPackageNames;
        } else {
          const playPackageNames = (
            await firebase.database().ref('resort-navigator').child('campground-locations/play/packageNames').once('value')
          ).val();
          packageNames = playPackageNames || {};
        }
      }
      window.localStorage.setItem('CAMPGROUND_PACKAGE_NAMES', JSON.stringify(packageNames || {}));

      if (campgroundLocation) {
        const keys = Object.keys(this.$options.campgroundMap);
        for (const key of keys) {
          if (campgroundLocation[this.$options.campgroundMap[key]]) {
            window.localStorage.setItem(key, campgroundLocation[this.$options.campgroundMap[key]]);
          } else {
            window.localStorage.setItem(key, '');
          }
        }
      }
    },
    unsetCampgroundLocationData: function () {
      window.localStorage.setItem('PREV_CAMPGROUND_KEY', this.getCampgroundKey);
      window.localStorage.removeItem('CAMPGROUND_KEY');
      window.localStorage.removeItem('CAMPGROUND_PACKAGE_NAMES');
      const keys = Object.keys(this.$options.campgroundMap);
      for (const key of keys) {
        window.localStorage.removeItem(key);
      }
    },
    getFeatureAvailability: function (feature) {
      const allFeatures = JSON.parse(window.localStorage.getItem('FEATURE_AVAILABILITY', ''));
      return allFeatures && allFeatures[feature];
    },
    getResortAdminStatus: function () {
      return window.localStorage.getItem(`RESORT_SYSTEM_ADMIN`) == true || window.localStorage.getItem('RESORT_SYSTEM_ADMIN') == 'true'
        ? true
        : false;
    },
    getAppList: async function () {
      const getAppListCF = firebase.functions().httpsCallable('getAppList');
      const editLocations = JSON.parse(window.localStorage.getItem(`EDIT_LOCATIONS`)) || [];
      return (await getAppListCF()).data
        .map((app) => {
          return { value: app.packageName.replace('com.armstrongsoft.', '').toLowerCase(), text: app.displayName };
        })
        .filter((app) => this.getResortAdminStatus() || editLocations.includes(app.value));
    },
    getIconSrc: function (icon, iconSet) {
      iconSet = iconSet || this.getIconSet();
      return `/iconsets/${iconSet}/${iconSet}_${icon}.png`;
    },
    getFormattedDay: function (date) {
      if (date) {
        return moment.tz(date, this.getTimeZone).format('ddd M/D');
      }
    },
    getFormattedDate: function (date) {
      if (date) {
        return moment.tz(date, this.getTimeZone).format('M/DD/YY');
      }
    },
    getFormattedTime: function (date) {
      if (date) {
        return moment.tz(date, this.getTimeZone).format('h:mm A');
      }
    },
    getCTKDefautDate: function () {
      return 'YYYY-MM-DD hh:mm a';
    },
    getISODate: function (date, format) {
      const isoFormat = 'YYYY-MM-DD kk:mm';
      if (!date) {
        return moment.tz(this.getTimeZone).format(isoFormat);
      }
      if (!format && isNaN(date)) {
        return moment.tz(date, this.getCTKDefautDate(), this.getTimeZone).format(isoFormat);
      } else if (!format) {
        return moment.tz(date, this.getTimeZone).format(isoFormat);
      }
      return moment.tz(date, format, this.getTimeZone).format(isoFormat);
    },
    getLocationRef: function (locationRef) {
      return firebase
        .database()
        .ref('resort-navigator')
        .child('location-data')
        .child(locationRef || this.getCampgroundKey);
    },
    async getAppStyle() {
      const result = await firebase
        .database()
        .ref('resort-navigator')
        .child('location-data')
        .child('system-data')
        .child('config')
        .child('app')
        .child('style')
        .once('value');
      return result.exists() ? result.val() : 'single'; //Defaults to single
    },
    async getAppType() {
      const result = await firebase
        .database()
        .ref('resort-navigator')
        .child('location-data')
        .child('system-data')
        .child('config')
        .child('app')
        .child('type')
        .once('value');
      return result.exists() ? result.val() : 'resort'; //Defaults to resort
    },
    async getCityState() {
      const city = await firebase
        .database()
        .ref('resort-navigator')
        .child('campground-locations')
        .child(this.getCampgroundKey)
        .child('city')
        .once('value');
      const state = await firebase
        .database()
        .ref('resort-navigator')
        .child('campground-locations')
        .child(this.getCampgroundKey)
        .child('state')
        .once('value');
      return { city: city.val(), state: state.val() };
    },
    getUserWrittenLocationContext: function () {
      return firebase.database().ref('resort-navigator').child('user-written').child(this.getCampgroundKey);
    },
    getContextRef: function (dbContext, communityKey) {
      const context = dbContext || this.dbContext;
      return firebase
        .database()
        .ref('resort-navigator')
        .child('location-data')
        .child(communityKey || this.getCampgroundKey)
        .child(context.replace('.', '/'));
    },
    getSelectKeyTitlePair: function (dbObject, useKey) {
      if (dbObject) {
        return Object.keys(dbObject).map((element) => {
          const key = useKey ? element : dbObject[element].key;
          return {
            text: dbObject[element].title,
            value: key,
          };
        });
      }
    },
    getSVG: function (passIconSet, colorIconBackground) {
      const backgroundColor = colorIconBackground || this.colorIconBackground;
      let backgroundSVG = '';
      switch (passIconSet) {
        case 'svg_vintage':
          backgroundSVG = `<rect x="0" y="0" width="80" height="80" rx="15" fill="${backgroundColor}" />`;
          break;
        case 'svg_traditional':
          backgroundSVG = `<rect x="2" y="2" width="76" height="76" rx="15" fill="none" stroke="${backgroundColor}" stroke-width="1" /><rect x="4" y="4" width="72" height="72" rx="14" fill="${backgroundColor}" />`;
          break;
        case 'svg_round':
          backgroundSVG = `<circle v-else-if="${passIconSet} == 'svg_round'" cx="40" cy="40" r="39" fill="${backgroundColor}" />`;
          break;
        case 'svg_parks':
          backgroundSVG = `<path fill="${backgroundColor}" d="M38 2.3660254037844a3 3 0 0 1 3 0l29.908965343809 17.267949192431a3 3 0 0 1 1.5 2.5980762113533l0 34.535898384862a3 3 0 0 1 -1.5 2.5980762113533l-29.908965343809 17.267949192431a3 3 0 0 1 -3 0l-29.908965343809 -17.267949192431a3 3 0 0 1 -1.5 -2.5980762113533l0 -34.535898384862a3 3 0 0 1 1.5 -2.5980762113533"/>`;
          break;
      }

      return `<svg viewBox="0 0 80 80">${backgroundSVG}</svg>`;
    },
    getMappedTitle(icon, iconSet) {
      return (iconSet == 'handdrawn' ? mappedIconsHanddrawn[icon] : mappedIcons[icon]) || icon || 'calendar';
    },
    getIconSet: function (iconSet) {
      return iconSet || window.localStorage.getItem('CAMPGROUND_ICON_SET') || 'round';
    },
    generateRandomIconColors: function (tempItem, iconColorShapeRandom, iconColorBackgroundRandom, iconSet) {
      const backgroundRGB = this.hexToRgb(this.getBackgroundColor);
      let iconShapeRGB = '';
      if (iconColorShapeRandom) {
        do {
          tempItem.colorIconShape = colorPalette[(colorPalette.length * Math.random()) | 0];
          iconShapeRGB = this.hexToRgb(tempItem.colorIconShape);
        } while (iconSet == 'svg_plain' && this.contrast(backgroundRGB, iconShapeRGB) < 3);
      }
      if (iconColorBackgroundRandom && iconSet !== 'svg_plain') {
        let iconBackgroundRGB = '';
        do {
          tempItem.colorIconBackground = colorPalette[(colorPalette.length * Math.random()) | 0];
          iconBackgroundRGB = this.hexToRgb(tempItem.colorIconBackground);
        } while (this.contrast(iconShapeRGB, iconBackgroundRGB) < 3 && this.contrast(backgroundRGB, iconBackgroundRGB));
      }
      return tempItem;
    },
    hexToRgb: function (hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
    },
    luminanace: function (r, g, b) {
      var a = [r, g, b].map(function (v) {
        v /= 255;
        return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
      });
      return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
    },
    contrast: function (rgb1, rgb2) {
      var lum1 = this.luminanace(rgb1[0], rgb1[1], rgb1[2]);
      var lum2 = this.luminanace(rgb2[0], rgb2[1], rgb2[2]);
      var brightest = Math.max(lum1, lum2);
      var darkest = Math.min(lum1, lum2);
      return (brightest + 0.05) / (darkest + 0.05);
    },
    // eslint-disable-next-line no-unused-vars
    composeSummary: function (item, entry, scoreMap) {
      let totalCount = 0;
      let correctCount = 0;
      let wrongCount = 0;
      for (const huntStep of item.steps) {
        const thisItem = entry[huntStep.key];
        if (huntStep.type === 'photo' && thisItem?.drawable) {
          totalCount++;

          const score = scoreMap ? scoreMap[huntStep.key] : thisItem?.scored;
          if (score === 1) {
            correctCount++;
          } else if (score === 0) {
            wrongCount++;
          }
        } else if (huntStep.type === 'answer' && thisItem?.answer) {
          totalCount++;

          const score = scoreMap ? scoreMap[huntStep.key] : thisItem?.scored;
          if (score === 1) {
            correctCount++;
          } else if (score === 0) {
            wrongCount++;
          }
        } else if (huntStep.type === 'multiple_choice_single' && thisItem?.answer) {
          totalCount++;

          const score = scoreMap ? scoreMap[huntStep.key] : thisItem?.scored;
          if (score === 1) {
            correctCount++;
          } else if (score === 0) {
            wrongCount++;
          }
        } else if (huntStep.type === 'multiple_choice_multiple' && thisItem?.answer) {
          totalCount++;

          const score = scoreMap ? scoreMap[huntStep.key] : thisItem?.scored;
          if (score === 1) {
            correctCount++;
          } else if (score === 0) {
            wrongCount++;
          }
        }
      }

      return {
        'Total Answers': totalCount,
        'Total Correct Answers': correctCount,
        'Total Incorrect Answers': wrongCount,
      };
    },
    checkForMessages: async function () {
      const menuItemSS = await firebase.database().ref('resort-navigator').child('location-data').child(this.getCampgroundKey).child('main-menu');
      menuItemSS.off();
      const result = await menuItemSS.orderByChild('uniqueId').equalTo('messages').once('value');
      return result.val();
    },
    csvExport(arrData) {
      console.log('csv array', arrData);
      const csvContent = arrData
        .map((item) => '"' + Object.values(item).join('","') + '"')
        .join('\n')
        .replace(/(^\[)|(\]$)/gm, '');

      const data = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', data);
      link.setAttribute('download', 'export.csv');
      link.click();
    },
    // async getMenuData(context) {
    //   console.log('getMenuData() context', context);

    //   const snapshot = await firebase
    //     .database()
    //     .ref('resort-navigator')
    //     .child('location-data')
    //     .child(this.getCampgroundKey)
    //     .child('main-menu')
    //     .orderByChild('uniqueId')
    //     .equalTo(context)
    //     .once('value');

    //   let result;
    //   snapshot.forEach((child) => {
    //     let value = child.val();
    //     if (value) {
    //       result = child.val();
    //       return;
    //     }
    //   });
    //   console.log('getMenuData() mainMenu', result);

    //   return result;
    // },
    pushToNotification: async function (newItem) {
      let menuItems = await this.checkForMessages();
      window.localStorage.setItem('event-notification', JSON.stringify({ newItem: newItem }));

      if (menuItems) {
        menuItems = Object.values(menuItems)[0];
        this.$router
          .push({
            path: `/amc-messages/${menuItems.uniqueId}/add`,
            query: {
              prevContext: this.$route && this.$route.params ? this.$route.params.context : null,
            },
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        const isResortAdmin =
          window.localStorage.getItem(`RESORT_SYSTEM_ADMIN`) == true || window.localStorage.getItem('RESORT_SYSTEM_ADMIN') == 'true' ? true : false;
        if (isResortAdmin) {
          const menuItemKeys = menuItems ? Object.keys(menuItems) : [];
          if (menuItemKeys && menuItemKeys[0]) {
            this.$router
              .push({
                path: `/amc-messages/${menuItems.uniqueId}/add`,
                params: {
                  context: 'systemwide-notification',
                  prevContext: this.$route && this.$route.params ? this.$route.params.context : null,
                },
              })
              .catch((err) => {
                console.error(err);
              });
          } else {
            this.$router
              .push({
                path: `/amc-messages/systemwide-notification/add`,
                params: {
                  prevContext: this.$route && this.$route.params ? this.$route.params.context : null,
                },
              })
              .catch((err) => {
                console.error(err);
              });
          }
          return;
        } else {
          const h = this.$createElement;
          // More complex structure
          const messageVNode = h('div', {
            domProps: {
              innerHTML:
                'Your app does not have a place to contain push notifications for users to see. Please add a section to your main-menu using the template "Messages"',
            },
          });
          this.$message.create({
            title: 'Feature Unavailable',
            body: 'This feature is not available.',
            buttons: [this.$message.button('OK')],
          });
          return;
        }
      }
    },
    loadFileAsText(file, fileTypes, callback, onError) {
      if (file && file instanceof File && fileTypes.includes(file.type)) {
        const reader = new FileReader();
        reader.onload = (e) => {
          callback(e.target.result);
        };
        reader.readAsText(file);
      } else {
        onError(file);
      }
    },
  },
  computed: {
    getCampgroundKey: function () {
      return window.localStorage.getItem('CAMPGROUND_KEY');
    },
    getCampgroundName: function () {
      return this.$store.getters.community.title || 'Loading...';
      //return window.localStorage.getItem('CAMPGROUND_NAME') || 'Resort Navigator';
    },
    getAppName: function () {
      switch (firebase.apps[0].options.projectId) {
        case 'camping-in-ontario':
          return 'CampON';
        case 'crr-lifestyle':
          return 'CRR Lifestyle';
        case 'bluegreen-vacations':
          return 'Bluegreen Vacations';
        case 'camp-ny':
          return 'Camp New York';
        default:
          return window.localStorage.getItem('CAMPGROUND_APP_NAME') || window.localStorage.getItem('CAMPGROUND_NAME');
      }
    },
    getCampgroundDrawable: function () {
      return window.localStorage.getItem('CAMPGROUND_DRAWABLE') || '';
    },
    getTimeZone: function () {
      return window.localStorage.getItem('CAMPGROUND_TIMEZONE');
    },
    getLatitude: function () {
      return window.localStorage.getItem('CAMPGROUND_LATITUDE');
    },
    getLongitude: function () {
      return window.localStorage.getItem('CAMPGROUND_LONGITUDE');
    },
    getPhone: function () {
      return window.localStorage.getItem('CAMPGROUND_PHONE');
    },
    isBusinessOnly: function () {
      return window.localStorage.getItem('CAMPGROUND_IS_BUSINESS_ONLY');
    },
    getTitle: function () {
      return window.localStorage.getItem('CAMPGROUND_TITLE');
    },
    getDisplayName: function () {
      return window.localStorage.getItem('CAMPGROUND_DISPLAY_NAME');
    },
    getIconColor: function () {
      return this.$store.getters.community.colorIconShape || window.localStorage.getItem('CAMPGROUND_COLOR_ICON_SHAPE') || this.getBackgroundColor;
    },
    getIconBackgroundColor: function () {
      return this.$store.getters.community.getIconBackgroundColor || window.localStorage.getItem('CAMPGROUND_COLOR_ICON_BACKGROUND') || '#FFFFFF';
    },
    getColorIconShapeRandom: function () {
      return this.$store.getters.community.colorIconShapeRandom || window.localStorage.getItem('CAMPGROUND_COLOR_ICON_SHAPE_RANDOM') || false;
    },
    getColorIconBackgroundRandom: function () {
      return (
        this.$store.getters.community.colorIconBackgroundRandom || window.localStorage.getItem('CAMPGROUND_COLOR_ICON_BACKGROUND_RANDOM') || false
      );
    },
    getBackgroundColor: function () {
      return this.$store.getters.community.colorBackground || window.localStorage.getItem('CAMPGROUND_COLOR_BACKGROUND') || '#1DA9F3';
      //return window.localStorage.getItem('CAMPGROUND_COLOR_BACKGROUND') || '#1DA9F3';
    },
    getPrimaryColor: function () {
      return this.$store.getters.community.colorPrimary || window.localStorage.getItem('CAMPGROUND_COLOR_PRIMARY') || '#183449';
      //return window.localStorage.getItem('CAMPGROUND_COLOR_PRIMARY') || '#183449';
    },
    getAccentColor: function () {
      return this.$store.getters.community.colorAccent || window.localStorage.getItem('CAMPGROUND_COLOR_ACCENT');
      //return window.localStorage.getItem('CAMPGROUND_COLOR_ACCENT');
    },
    getHighlightedTextColor: function () {
      return window.localStorage.getItem('CAMPGROUND_COLOR_HIGHLIGHTEDTEXT');
    },
    getBackgroundColorStyle: function () {
      return `background-color:${this.getBackgroundColor};`;
    },
    getPrimaryColorStyle: function () {
      return `background-color:${this.getPrimaryColor};`;
    },
    getAccentColorStyle: function () {
      return `background-color:${this.getAccentColor};`;
    },
    getHighlightedTextColorStyle: function () {
      return `background-color:${this.getAccentColor};`;
    },
    isDollarsEnabled() {
      return window.localStorage.getItem('DOLLARS_ENABLED') === 'true';
    },
  },
};
