<template>
  <div>
    <div v-if="checkLoginCompleted">
      <link rel="stylesheet" href="//fonts.googleapis.com/icon?family=Material+Icons" />
      <div v-if="noLeftColumn()">
        <router-view />
      </div>
      <div id="app" v-else>
        <div id="left-column">
          <div class="d-material-icons">
            <img src="/amc_logo.png" alt="" id="amc-logo" :class="{ pointer: isNotRemote }" @click="goHome()" />
            <i id="menu-admin-expander" class="material-icons logo-icons" @click="menuDisplayCSS = true" @hideMenu="menuDisplayCSS = false">menu</i>
            <i v-if="isNotRemote" class="material-icons logo-icons" @click="goHome()">home</i>
            <i v-if="menuDisplayCSS" id="menu-admin-close" class="material-icons logo-icons" @click="menuDisplayCSS = false">close</i>
          </div>

          <ButtonNavigation buttonSection="admin" :menuDisplayCSS="this.menuDisplayCSS" @hideMenu="menuDisplayCSS = false" />
        </div>
        <div>
          <Header :campgroundname="getCampgroundName" :style="getPrimaryColorStyle" />
          <div id="content-column" :style="getBackgroundColorStyle">
            <router-view />
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-device-type"
      ref="deviceTypeModal"
      title="Personal Device"
      size="lg"
      :buttons="[
        { title: 'Cancel', variant: 'btn-secondary' },
        { title: 'Login', variant: 'btn-accent', action: () => deviceTypeSelected() },
      ]">
      <template #body>
        <div>Is this a personal device or a shared device between people(e.g. library computer)</div>
        <div class="form-check">
          <input class="form-check-input" type="radio" v-model="personalDevice" name="device-type" id="personal-device" value="true" />
          <label class="form-check-label" for="personal-device"> Personal device, only used by myself (Stay logged in) </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" v-model="personalDevice" name="device-type" id="shared-device" value="false" />
          <label class="form-check-label" for="shared-device"> Shared device used by others (You will be logged out at browser close) </label>
        </div>
      </template>
      <!-- <template #footer>
        <div class="w-100">
          <b-button size="sm" class="btn float-right" @click="deviceTypeSelected">Login</b-button>
        </div>
      </template> -->
    </b-modal>
  </div>
</template>

<script>
import Header from './components/Header.vue';
import ButtonNavigation from './components/ButtonNavigation.vue';
import { LoginMixin } from './components/mixins/LoginMixin.js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import BModal from './components/common/BModal.vue';

export default {
  name: 'app',
  components: {
    Header,
    ButtonNavigation,
    BModal,
  },
  mixins: [LoginMixin],
  data() {
    return { checkLoginCompleted: false, personalDevice: true, menuDisplayCSS: false };
  },
  computed: {
    isNotRemote: function () {
      return !this.$route.meta.isRemote;
    },
  },
  async created() {
    const featureAvailability = (await firebase.database().ref('resort-navigator').child('feature-availability').once('value')).val() || {};
    window.localStorage.setItem('FEATURE_AVAILABILITY', JSON.stringify(featureAvailability));
    const isLoginNeeded = await this.checkLoginAndEmail();
    if (isLoginNeeded) {
      this.deviceTypeModal.show();
    } else {
      if (this.$route.name == `Login` && this.$route.query.action == 'logout') {
        this.unsetCampgroundLocationData();
      } else if (this.$route.name != `Login` && this.$route.name != `Studio` && firebase.auth().currentUser && firebase.auth().currentUser.uid) {
        await this.authorizeUser(firebase.auth().currentUser.uid);
      }
      this.checkLoginCompleted = true;
    }
  },
  methods: {
    noLeftColumn: function () {
      return this.$route.meta.noLeftColumn;
    },
    async deviceTypeSelected() {
      await this.completeSignInWithPersistence(this.personalDevice);
      this.checkLoginCompleted = true;
    },
    async completeSignInWithPersistence(personalDevice) {
      const auth = firebase.auth();
      auth.setPersistence(personalDevice ? firebase.auth.Auth.Persistence.LOCAL : firebase.auth.Auth.Persistence.SESSION);
      let email = window.localStorage.getItem('emailForSignIn');
      await auth;
      await auth
        .signInWithEmailLink(email, window.location.href)
        .then(async (result) => {
          console.log('🚀 ~ file: Login.vue ~ line 26 ~ .then ~ result', JSON.stringify(result));
          // Clear email from storage.
          window.localStorage.removeItem('emailForSignIn');
          this.$router.replace({ name: 'Home' });
          this.authorizeUser(result.user.uid, true);
        })

        .catch((error) => {
          window.localStorage.removeItem('emailForSignIn');
          window.alert(error.message);
          this.$router.replace({ name: 'Login' });
          console.log('🚀 ~ file: Login.vue ~ line 36 ~ created ~ error', error);
        });
    },
    checkLoginAndEmail() {
      // Confirm the link is a sign-in with email link.
      const auth = firebase.auth();
      if (auth.isSignInWithEmailLink(window.location.href)) {
        this.unsetCampgroundLocationData();
        window.localStorage.removeItem('CAMPGROUND_KEY');
        let email = window.localStorage.getItem('emailForSignIn');
        if (!email) {
          email = window.prompt('Please provide your email for confirmation');
          window.localStorage.setItem('emailForSignIn', email);
          if (email == null) this.$router.replace({ name: 'Login' });
        }
        console.log('🚀 ~ file: LoginMixins.js ~ line 84 ~ checkLoginAndEmail ~ true', true);
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped>
#app {
  display: grid;
}
</style>

<style lang="scss">
@import 'bootstrap/dist/css/bootstrap.css';
$theme-colors: (
  'primary': #183449,
  'secondary': #1da9f3,
  'info': #183449,
  'primarybg': #18344930,
  'accent': #01f2a6,
  'light': #f8f9fa,
  'danger': #dc3545,
  'warning': #ffc107,
  'success': #198754,
);
$grid-breakpoints: (
  xxxs: 0,
  xxs: 320px,
  xs: 568px,
  sm: 667px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1600px,
);
$container-max-widths: (
  xxxs: 0,
  xxs: 320px,
  xs: 568px,
  sm: 667px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1600px,
);

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/bootstrap';

@import '@vueform/multiselect/themes/default.css';

body {
  margin: 0;
}
header {
  width: 490px;
}
.b-calendar header,
.b-time header {
  width: initial;
}

.b-form-datepicker button,
.b-form-timepicker button {
  margin-bottom: 0px;
}
.btn-secondary {
  --bs-btn-color: white !important;
  --bs-btn-hover-color: white !important;
}
@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant(
      $value,
      $value,
      $hover-background: darken($value, 10%),
      $hover-border: darken($value, 10%),
      $active-background: darken($value, 10%),
      $active-border: darken($value, 12.5%)
    );
  }
}
.form-check-input {
  min-width: 16px !important;
  max-width: 16px !important;
  padding: 0px !important;
}
.custom-tooltip {
  z-index: 9999;
}

.sl-toast-stack {
  z-index: 999998 !important;
}

#app {
  grid-template-columns: 1.1fr minmax(490px, 3fr);
  align-items: start;
  grid-gap: 20px;
  width: 100vw;
  /* max-width: 1010px;
    margin: auto; */
}

#left-column {
  z-index: 1;
  position: sticky;
  top: 0px;
  display: grid;
  grid-gap: 4px;
  background-color: white;
}
#center-column,
div.two-column.no-content-column {
  min-height: calc(100vh - 88px);
  overflow-y: auto;
}

#content-column > div.two-column,
div.two-column.no-content-column {
  height: calc(100vh - 88px);
  display: grid;
  grid-template-columns: 490px 1fr;
}
#right-column {
  overflow-y: scroll;
  background-color: white;
}
#amc-logo {
  justify-self: center;
  grid-column: 1 / -1;
  margin: 10px;
}

.material-icons.logo-icons {
  color: map-get($theme-colors, 'secondary');
  font-size: xxx-large;
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
}

.form-container {
  background-color: map-get($theme-colors, 'primarybg');
  border: 1px solid map-get($theme-colors, 'secondary');
}
form .container {
  padding: 0px;
}

.form-columns {
  display: flex;
}

.form-columns > div {
  width: 50%;
  margin-right: 10px;
  float: left;
  text-align: left;
}

label.traytitle {
  grid-column: 1 / -1;
  text-align: left;
  color: white;
  background-color: map-get($theme-colors, 'secondary');
  padding: 0px 4px;
  height: 24px;
  line-height: 24px;
  text-transform: uppercase;
  font-weight: 600;
}
.form-columns > div:last-child {
  margin-right: 0px;
}

.cr-slider::-webkit-slider-thumb {
  background-color: map-get($theme-colors, 'secondary') !important;
}

ul.editable-list {
  list-style: none;
  padding: 0px;
}
ul.editable-list li {
  border: 1px solid map-get($theme-colors, 'secondary');
  background: map-get($theme-colors, 'primarybg');
  border-radius: 4px;
  margin-bottom: 8px;
  margin-right: 8px;
  padding: 0px 10px;
  cursor: pointer;
  position: relative;
}
ul.editable-list li i.item-remove {
  position: absolute;
  top: 0px;
  right: -2px;
  color: map-get($theme-colors, 'secondary');
}

li.single-row i.item-remove {
  top: 2px !important;
}
.single-row div {
  padding: 2px 10px;
}
.single-row.list-item .material-icons {
  color: map-get($theme-colors, 'secondary');
}
.material-icons {
  vertical-align: text-top;
}
#center-column {
  color: white;
}
#item-header {
  position: relative;
  background-size: cover;
  background-position: center center;
}
#item-header h1 {
  background-color: #1b1b1bcc;
  position: absolute;
  width: 100%;
  height: 54px;
  top: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 1000;
}
.field-clear-button {
  right: 2px !important;
}
.field-input {
  padding-right: 2px !important;
}
.datetimepicker button {
  margin-bottom: 0px;
}
.form-select {
  max-height: 39px;
}

#menu-admin-expander {
  display: none;
}
@media screen and (max-width: 981px) and (orientation: portrait), (max-width: 740px) {
  header {
    width: 100vw;
  }
  #content-column > div.two-column,
  div.two-column.no-content-column {
    height: calc(100vh - 88px);
    display: grid;
    grid-template-columns: 1fr;
  }

  .grid-container {
    padding: 0px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
  }
  #amc-logo {
    display: none;
  }
  #menu-admin {
    position: relative;
    display: none;
    height: 100vh;
    width: 100vw;
    z-index: 999999;
  }

  #menu-admin.menu-display {
    display: initial;
  }

  #menu-admin-expander {
    display: initial;
  }
  #app {
    grid-template-rows: auto;
    grid-template-columns: unset;
  }
  #right-column {
    overflow-y: initial;
    background-color: white;
  }
}
@media screen and (min-width: 1400px) {
  #app {
    grid-template-columns: 1.5fr minmax(490px, 3fr);
  }
}
.icon-wrapper {
  height: 75px;
}
.IZ-select .icon-wrapper {
  height: 100px;
}
.icon-wrapper svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75px;
}
.fa-size {
  font-size: 2.5em;
}
.icon-selected {
  min-width: 100px;
}
.svg-inline--fa svg {
  display: inline-block;
  font-size: inherit;
  height: 1.1em;
  width: 1.1em;
  overflow: visible;
  vertical-align: -0.125em;
}
svg:not(:root).svg-inline--fa {
  overflow: visible;
}
.svg-inline--fa.fa-w-14 svg path,
.use-default-color svg path {
  fill: currentColor;
}
#update-colors-modal .icon-bg svg {
  height: 100%;
}
.IZ-select .icon-bg svg,
.phone-container .icon-bg svg {
  height: 80%;
}
.phone-container .svg-inline--fa svg {
  height: 0.8em;
}
#icon .IZ-select__input {
  height: 102px;
}
#icon-list .IZ-select__input:not(.IZ-select__input--disabled) input {
  margin: 10px;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
#icon-list .IZ-select__input.IZ-select__input--disabled input {
  display: none;
}

.amc-modal .modal-content {
  background-color: #0c1a25;
  background-image: linear-gradient(40deg, rgba(29, 169, 243, 0.3) 0%, #0c1a25 100%);
  /* width: 300px; */
  border-radius: 12px;
  margin: auto;
  /* padding: 30px; */
  color: #01f2a6;
}

.amc-modal .modal-content .modal-body,
.amc-modal .modal-header {
  color: white !important;
}
.amc-modal .modal-header {
  width: 100%;
}
</style>
./components/common/BModal.vue/index.js
