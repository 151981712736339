<template>
  <div>
    <div class="alert position-fixed fixed-bottom m-0 rounded-0" :class="`alert-${toast.variant}`" v-show="toast.dismissOn" role="alert">
      {{ toast.message }}
      <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>
    <div class="position-relative">
      <div class="bg-light d-flex align-items-center justify-content-center">
        <div class="two-column">
          <div id="map-edit">
            <div class="button-header d-flex">
              <img src="/amc_logo.png" alt="" id="amc-logo" @click="goHome()" />
              <i id="home-icon" class="material-icons" @click="goHome()">home</i>
            </div>
            <canvas class="m-1 drawingcanvas" ref="can" style="border: 1px solid #0c1a25"></canvas>
            <canvas class="m-1 d-none" ref="can-hidden"></canvas>
          </div>
          <div id="right-column" class="p-2" style="margin-left: 16px">
            <div class="default-padding form-container">
              <div class="row">
                <div class="col">Background photo</div>
              </div>
              <div class="row justify-content-center">
                <div class="col">
                  <input
                    type="file"
                    class="form-control"
                    accept=".jpg, .png"
                    placeholder="Set background photo or drop here"
                    @change="onBackgroundSelected" />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col">Logo</div>
              </div>
              <div class="row justify-content-center">
                <div class="col">
                  <input type="file" class="form-control" accept=".jpg, .png" placeholder="Set Logo or drop here" @change="onLogoSelected" />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col">Phone</div>
              </div>
              <div class="row justify-content-center">
                <div class="col">
                  <input type="file" class="form-control" accept=".jpg, .png" placeholder="Set Phone or drop here" @change="setPhone" />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col">Title</div>
              </div>
              <div class="row">
                <div class="col">
                  <input type="text" class="form-control" v-model="pageData.title" placeholder="Title" @input="setTitle(pageData.title)" />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col">Sub title</div>
              </div>
              <div class="row">
                <div class="col">
                  <input
                    type="text"
                    class="form-control"
                    v-model="pageData.subtitle"
                    placeholder="Sub Title"
                    @input="setSubTitle(pageData.subtitle)" />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col">
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="qrCodeVisibility"
                      v-model="pageData.qrCodeVisibility"
                      @change="toggleQrCode" />
                    <label class="form-check-label" for="qrCodeVisibility">Show QR code</label>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col">
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="andMoreVisibility"
                      v-model="pageData.andMoreVisibility"
                      @change="toggleAndMoreVisibility" />
                    <label class="form-check-label" for="andMoreVisibility">Show "And more"</label>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col">
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="feature1Visibility"
                      v-model="pageData.features[0].visibility"
                      @change="setFeature(0, pageData.features[0].name, pageData.features[0].iconData, pageData.features[0].visibility)" />
                    <label class="form-check-label" for="feature1Visibility">Feature 1</label>
                  </div>
                </div>
              </div>
              <div class="row justify-content-center align-items-center">
                <div class="col">
                  <input
                    type="text"
                    class="form-control"
                    v-model="pageData.features[0].name"
                    placeholder="Name"
                    @input="setFeature(0, pageData.features[0].name, pageData.features[0].iconData, pageData.features[0].visibility)" />
                </div>
                <div class="col mt-2">
                  <IconList
                    icon-only
                    transparent-background
                    :itemId="pageData.features[0].id"
                    v-model="pageData.features[0].iconData"
                    @input="setFeature(0, pageData.features[0].name, pageData.features[0].iconData, pageData.features[0].visibility)" />
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="feature2Visibility"
                      v-model="pageData.features[1].visibility"
                      @change="setFeature(1, pageData.features[1].name, pageData.features[1].iconData, pageData.features[1].visibility)" />
                    <label class="form-check-label" for="feature2Visibility">Feature 2</label>
                  </div>
                </div>
              </div>
              <div class="row justify-content-center align-items-center">
                <div class="col">
                  <input
                    type="text"
                    class="form-control"
                    v-model="pageData.features[1].name"
                    placeholder="Name"
                    @input="setFeature(1, pageData.features[1].name, pageData.features[1].iconData, pageData.features[1].visibility)" />
                </div>
                <div class="col mt-2">
                  <IconList
                    icon-only
                    transparent-background
                    :itemId="pageData.features[1].id"
                    v-model="pageData.features[1].iconData"
                    @input="setFeature(1, pageData.features[1].name, pageData.features[1].iconData, pageData.features[1].visibility)" />
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="feature3Visibility"
                      v-model="pageData.features[2].visibility"
                      @change="setFeature(2, pageData.features[2].name, pageData.features[2].iconData, pageData.features[2].visibility)" />
                    <label class="form-check-label" for="feature3Visibility">Feature 3</label>
                  </div>
                </div>
              </div>
              <div class="row justify-content-center align-items-center">
                <div class="col">
                  <input
                    type="text"
                    class="form-control"
                    v-model="pageData.features[2].name"
                    placeholder="Name"
                    @input="setFeature(2, pageData.features[2].name, pageData.features[2].iconData, pageData.features[2].visibility)" />
                </div>
                <div class="col mt-2">
                  <IconList
                    icon-only
                    transparent-background
                    :itemId="pageData.features[2].id"
                    v-model="pageData.features[2].iconData"
                    @input="setFeature(2, pageData.features[2].name, pageData.features[2].iconData, pageData.features[2].visibility)" />
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <button
                    class="btn"
                    :style="{ backgroundColor: pageData.fontColor.hex8, width: '24px', height: '24px', border: '1px solid #0b85c6' }"
                    @click.stop="showFontColorPicker"
                    v-tooltip-bottom="'Pick Color'">
                    <div style="z-index: 1000; position: relative">
                      <chrome-picker
                        id="editFontColor"
                        :data-edit="editFontColor"
                        v-show="editFontColor"
                        v-model="pageData.fontColor"
                        v-click-outside="hideFontColorPicker"
                        @input="onFontColorPickerChanged" />
                    </div>
                  </button>
                  <label style="margin-left: 16px">Font color</label>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col">
                  <button
                    class="btn"
                    :style="{ backgroundColor: pageData.borderColor.hex8, width: '24px', height: '24px', border: '1px solid #0b85c6' }"
                    @click.stop="showBorderColorPicker"
                    v-tooltip-bottom="'Pick Color'">
                    <div style="z-index: 1000; position: relative">
                      <chrome-picker
                        id="editBorderColor"
                        :data-edit="editBorderColor"
                        v-show="editBorderColor"
                        v-model="pageData.borderColor"
                        v-click-outside="hideBorderColorPicker"
                        @input="onBorderColorPickerChanged" />
                    </div>
                  </button>
                  <label style="margin-left: 16px">Border color</label>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col">
                  <button
                    class="btn"
                    :style="{ backgroundColor: pageData.backgroundOverlayColor.hex8, width: '24px', height: '24px', border: '1px solid #0b85c6' }"
                    @click.stop="showBackgroundShadeColorPicker"
                    v-tooltip-bottom="'Pick Color'">
                    <div style="z-index: 1000; position: relative">
                      <chrome-picker
                        id="editBackgroundShadeColor"
                        :data-edit="editBackgroundShadeColor"
                        v-show="editBackgroundShadeColor"
                        v-model="pageData.backgroundOverlayColor"
                        v-click-outside="hideBackgroundShadeColorPicker"
                        @input="onBackgroundOverlayColorPickerChanged" />
                    </div>
                  </button>
                  <label style="margin-left: 16px">Background shade color</label>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col">
                  <button class="btn btn-block btn-secondary w-100" @click="save" style="z-index: -99">SAVE TEMPLATE</button>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col">
                  <button class="btn btn-block btn-secondary w-100" @click="downloadCanvas" style="z-index: -99">DOWNLOAD IMAGE</button>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col">
                  <button class="btn btn-block btn-secondary w-100" @click="saveAndDownload" style="z-index: -99">SAVE & DOWNLOAD</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fabric } from 'fabric';
import { Chrome } from 'vue-color';
import Vue from 'vue';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import { LoginMixin } from '@/components/mixins/LoginMixin';
import icons from '@/json/icons.json';
import IconList from '../components/inputfields/IconList';

export default {
  mixins: [LoginMixin],
  data() {
    let iconList = icons.filter((item) => !item.communities || item.communities.includes(this.$fbProject));

    return {
      toast: {
        duration: 3,
        dismissOn: 0,
        message: '',
        variant: 'success',
      },
      filteredIcons: iconList,
      icons: iconList,
      canvas: null,
      canvasOriginalWidth: 900,
      canvasOriginalHeight: 900,
      canvasWidth: 900,
      canvasHeight: 900,
      imgWidth: 0,
      imgHeight: 0,
      bgImage: 0,
      canvasScale: 1,
      dbRef: null,
      editFontColor: false,
      editBorderColor: false,
      editBackgroundShadeColor: false,
      showProgressModal: false,
      pageData: {
        fontColor: {
          hex8: '#FFFFFFFF',
        },
        borderColor: {
          hex8: this.accentColor,
        },
        backgroundOverlayColor: {
          hex8: '#00000066',
          hex: '#00000066',
        },
        qrCodeVisibility: true,
        andMoreVisibility: true,
        title: 'INTRODUCING OUR MOBILE APP',
        subtitle: 'EVERYTHING YOU NEED IN THE PALM OF YOUR HAND',
        phoneUrl: '',
        logoUrl: '',
        bgUrl: '',
        features: [
          {
            id: 'feature0',
            visibility: true,
            name: 'Notifications',
            iconData: {
              iconSet: null,
              title: 'bell',
              colorIconShape: null,
              svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z"/></svg>',
              colorIconBackground: null,
            },
          },
          {
            id: 'feature1',
            visibility: true,
            name: 'Business listings',
            iconData: {
              iconSet: null,
              title: 'map-marker-alt',
              colorIconShape: null,
              svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"/></svg>',
              colorIconBackground: null,
            },
          },
          {
            id: 'feature2',
            visibility: true,
            name: 'Calendar',
            iconData: {
              iconSet: null,
              title: 'calendar-alt',
              colorIconShape: null,
              svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z"/></svg>',
              colorIconBackground: null,
            },
          },
        ],
      },
      packageNames: {
        ios: '',
        iosId: null,
        android: '',
        tableId: '',
        oneLink: '',
        qrCode: '',
        phoneOnlyImage: '',
        introducingMarketingImage: '',
        ongoingMarketingImage: '',
      },
    };
  },
  watch: {},
  async created() {
    this.packageNames = {
      ...this.packageNames,
      ...((
        await firebase
          .database()
          .ref('resort-navigator')
          .child('campground-locations')
          .child(this.getCampgroundKey)
          .child('packageNames')
          .once('value')
      ).val() || (await firebase.database().ref('resort-navigator').child('location-data/system-data/config/packageNames').once('value')).val()),
    };

    this.initialize();
  },
  mounted() {
    const ref = this.$refs.can;
    this.canvas = new fabric.Canvas(ref);

    this.canvas.selection = false;
    let vm = this;
    this.canvas.on('selection:created', function () {
      vm.isPanning = false;
    });
  },
  computed: {
    accentColor: function () {
      return `${this.getAccentColor}ff`;
    },
  },
  methods: {
    onAlertCountdownChanged(value) {
      this.toast.dismissOn = value;

      if (this.toast.dismissOn === 0) {
        this.toast.message = '';
      }
    },
    showToast(variant = 'success') {
      this.toast.variant = variant;
      this.toast.dismissOn = this.toast.duration;
    },
    async initialize() {
      const db = firebase.database();
      const dbRef = db.ref(`resort-navigator/location-data/${this.getCampgroundKey}/marketing-asset-editor`);

      try {
        const { fontColor, borderColor, backgroundOverlayColor, ...others } = (await dbRef.once('value')).val();

        this.pageData = {
          fontColor: {
            hex8: fontColor,
          },
          borderColor: {
            hex8: borderColor,
          },
          backgroundOverlayColor: {
            hex8: backgroundOverlayColor,
            hex: backgroundOverlayColor,
          },
          ...others,
        };

        await this.onBackgroundSelected(this.pageData.bgUrl);
        // eslint-disable-next-line no-empty
      } catch (e) {
        await this.onBackgroundSelected(null);
      }
    },
    showBorderColorPicker() {
      this.editBorderColor = true;
    },
    hideBorderColorPicker() {
      this.editBorderColor = false;
    },
    showBackgroundShadeColorPicker() {
      this.editBackgroundShadeColor = true;
    },
    hideBackgroundShadeColorPicker() {
      this.editBackgroundShadeColor = false;
    },
    showFontColorPicker() {
      this.editFontColor = true;
    },
    hideFontColorPicker() {
      this.editFontColor = false;
    },
    onBorderColorPickerChanged(value) {
      this.createBorder(value.hex8);
    },
    onBackgroundOverlayColorPickerChanged(value) {
      const overlay = this.getFabricObjectFromId('backgroundOverlay', this.canvas.getObjects());
      if (overlay) {
        overlay.set({ fill: value.hex8 });
        this.canvas.renderAll();
      }
    },
    onFontColorPickerChanged() {
      const prevAvailableOn = this.getFabricObjectFromId('availableOn', this.canvas.getObjects());
      const prevTitle = this.getFabricObjectFromId('title', this.canvas.getObjects());
      const prevSubtitle = this.getFabricObjectFromId('subtitle', this.canvas.getObjects());
      const prevAndMore = this.getFabricObjectFromId('andMore', this.canvas.getObjects());

      try {
        this.canvas.remove(prevAvailableOn);
        this.canvas.remove(prevTitle);
        this.canvas.remove(prevSubtitle);
        this.canvas.remove(prevAndMore);
      } catch (e) {
        console.error(e);
        this.canvas.renderAll();
      }
      const fabAndMoreText = new fabric.Text('And More!'.toUpperCase(), {
        id: 'andMore',
        fontSize: 20,
        originX: 'center',
        originY: 'center',
        left: 0.5 * this.canvasWidth,
        top: 0.92 * this.canvasHeight,
        fill: this.pageData.fontColor.hex8,
      });
      this.canvas.add(fabAndMoreText);

      const fabText = new fabric.Text('Available on:'.toUpperCase(), {
        id: 'availableOn',
        fontSize: 16,
        originX: 'center',
        originY: 'center',
        top: 0.965 * this.canvasHeight,
        left: 0.43 * this.canvasWidth,
        fill: this.pageData.fontColor.hex8,
      });
      this.canvas.add(fabText);
      this.canvas.renderAll();
      this.setTitle(this.pageData.title);
      this.setSubTitle(this.pageData.subtitle);
      this.resetFeatures();
    },
    resetFeatures() {
      this.setFeature(0, this.pageData.features[0].name, this.pageData.features[0].iconData, this.pageData.features[0].visibility);
      this.setFeature(1, this.pageData.features[1].name, this.pageData.features[1].iconData, this.pageData.features[1].visibility);
      this.setFeature(2, this.pageData.features[2].name, this.pageData.features[2].iconData, this.pageData.features[2].visibility);
    },
    setCanvasZoom: function () {
      this.canvasWidth = this.canvasOriginalWidth * this.canvasScale;
      this.canvasHeight = this.canvasOriginalHeight * this.canvasScale;

      this.canvas.setWidth(this.canvasWidth);
      this.canvas.setHeight(this.canvasHeight);
    },
    scaleAndPositionBackgroundImage: function () {
      this.setCanvasZoom();

      var canvasAspect = this.canvasWidth / this.canvasHeight;
      var imgAspect = this.bgImage.width / this.bgImage.height;
      var left, top;

      if (canvasAspect >= imgAspect) {
        this.scaleFactor = this.canvasWidth / this.bgImage.width;
        left = 0;
        top = -(this.bgImage.height * this.scaleFactor - this.canvasHeight) / 2;
      } else {
        this.scaleFactor = this.canvasHeight / this.bgImage.height;
        top = 0;
        left = -(this.bgImage.width * this.scaleFactor - this.canvasWidth) / 2;
      }

      this.canvas.setBackgroundImage(this.bgImage, this.canvas.renderAll.bind(this.canvas), {
        top: top,
        left: left,
        originX: 'left',
        originY: 'top',
        scaleX: this.scaleFactor,
        scaleY: this.scaleFactor,
      });
      this.canvas.renderAll.bind(this.canvas);
    },
    async onBackgroundSelected(bg) {
      this.showProgressModal = true;
      let url;

      try {
        if (typeof bg === 'string' && bg.startsWith('http')) {
          url = bg;
        } else if (bg) {
          url = await this.uploadImage(bg, 'bg');
          this.pageData.bgUrl = url;
        }
      } catch (e) {
        url = await this.uploadImage(bg, 'bg');
        this.pageData.bgUrl = url;
      }

      if (!bg) {
        fabric.loadSVGFromURL('/no_image_placeholder.svg', async (obj, options) => {
          let svg = fabric.util.groupSVGElements(obj, options);
          await this.setBackgroundCanvas(svg);
        });
      } else {
        fabric.Image.fromURL(
          url,
          async function (img) {
            await this.setBackgroundCanvas(img);
          }.bind(this),
          {
            crossOrigin: 'anonymous',
          }
        );
      }
    },
    createBorder(hex8) {
      const previousBorder = this.getFabricObjectFromId('border', this.canvas.getObjects());
      if (previousBorder) {
        this.canvas.remove(previousBorder);
      }
      const border = new fabric.Rect({
        id: 'border',
        width: 780,
        height: 780,
        strokeWidth: 4,
        stroke: hex8,
        fill: 'transparent',
      });
      this.canvas.add(border);
      border.moveTo(1);
      this.canvas.centerObject(border);
      this.canvas.renderAll();
    },
    async setBackgroundCanvas(fabricObj) {
      const vm = this;

      fabricObj.set({
        id: 'background',
      });
      vm.bgImage = fabricObj;
      vm.scaleAndPositionBackgroundImage();
      const overlay = new fabric.Rect({
        id: 'backgroundOverlay',
        width: vm.canvasWidth,
        height: vm.canvasHeight,
        fill: vm.pageData.backgroundOverlayColor.hex8,
      });
      vm.canvas.add(overlay);
      overlay.moveTo(0);
      vm.canvas.renderAll();

      let borderColor = vm.pageData.borderColor.hex8;

      if (!borderColor) {
        borderColor = vm.accentColor;
        Vue.set(vm.pageData.borderColor, 'hex8', borderColor);
      }
      this.createBorder(borderColor);
      const fabText = new fabric.Text('Available on:'.toUpperCase(), {
        id: 'availableOn',
        fontSize: 16,
        originX: 'center',
        originY: 'center',
        top: 0.965 * vm.canvasHeight,
        left: 0.43 * vm.canvasWidth,
        fill: vm.pageData.fontColor.hex8,
      });
      vm.canvas.add(fabText);
      vm.canvas.renderAll();
      // original google play image link
      // https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png
      fabric.loadSVGFromURL('/google_play_store_badge_en.svg', (obj, options) => {
        let svg = fabric.util.groupSVGElements(obj, options);
        svg.set('id', 'googlePlay');
        svg.set('top', 0.945 * vm.canvasHeight);
        svg.set('left', 0.53 * vm.canvasWidth);
        svg.scaleToWidth(135.8);
        svg.selectable = false;
        vm.canvas.add(svg);
        vm.canvas.renderAll();
      });

      // original apple image link:
      // https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=135x40&amp;releaseDate=1617580800&h=162d5a71490a3e8a731aa2c6c51c2cad
      fabric.loadSVGFromURL('/apple_app_store_badge_en.svg', (obj, options) => {
        let svg = fabric.util.groupSVGElements(obj, options);
        svg.set('id', 'appleAppStore');
        svg.set('top', 0.945 * vm.canvasHeight);
        svg.set('left', 0.7 * vm.canvasWidth);
        svg.scaleToWidth(120);
        svg.selectable = false;
        vm.canvas.add(svg);
        vm.canvas.renderAll();
      });

      // load defaults
      await vm.onLogoSelected(vm.pageData.logoUrl);
      await vm.setPhone(vm.pageData.phoneUrl);
      vm.setTitle(vm.pageData.title);
      vm.setSubTitle(vm.pageData.subtitle);
      vm.resetFeatures();
      vm.toggleAndMoreVisibility();
      vm.toggleQrCode();
      vm.canvas.forEachObject((obj) => {
        obj.selectable = false;
      });
      vm.showProgressModal = false;
    },
    async onLogoSelected(logo) {
      if (!logo) return;

      this.showProgressModal = true;
      const fabLogo = this.getFabricObjectFromId('logo', this.canvas.getObjects());

      if (fabLogo) {
        this.canvas.remove(fabLogo);
        this.canvas.renderAll();
      }

      let vm = this;
      let url;

      if (typeof logo === 'string' && logo.startsWith('http')) {
        url = logo;
      } else {
        url = await this.uploadImage(logo, 'logo');
      }

      this.pageData.logoUrl = url;
      fabric.Image.fromURL(
        url,
        function (img) {
          img.set({
            id: 'logo',
            originX: 'center',
            originY: 'center',
            left: 0.5 * vm.canvasWidth,
            top: 0.065 * vm.canvasHeight,
          });

          if (img.height > img.width) {
            img.scaleToHeight(100);
          } else {
            img.scaleToWidth(100);
          }

          img.selectable = false;
          vm.canvas.add(img);
          vm.canvas.renderAll();
          vm.showProgressModal = false;
        }.bind(this),
        {
          crossOrigin: 'anonymous',
        }
      );
    },
    // kind refers what kind of image is
    // the image for upload.
    // which are bg, logo and phone
    async uploadImage(bg, kind) {
      const file = bg.name ? bg : bg.target.files[0];
      const storage = firebase.storage();
      const imageRef = storage.ref(`${this.getCampgroundKey}/images/marketing_asset_editor/${kind}.${file.name.split('.')[1]}`);
      await imageRef.put(file);
      return await imageRef.getDownloadURL();
    },
    async setPhone(file) {
      this.showProgressModal = true;
      const fabPhone = this.getFabricObjectFromId('phone', this.canvas.getObjects());

      if (fabPhone) {
        this.canvas.remove(fabPhone);
        this.canvas.renderAll();
      }

      let phoneUrl = this.packageNames.phoneOnlyImage;

      if (typeof file !== 'string') {
        phoneUrl = await this.uploadImage(file, 'phone');
      } else if (file) {
        phoneUrl = file;
      }

      let vm = this;

      this.pageData.phoneUrl = phoneUrl;

      fabric.Image.fromURL(
        phoneUrl,
        function (img) {
          img.set({
            id: `phone`,
            originX: 'center',
            originY: 'center',
            left: 0.5 * vm.canvasWidth,
            top: 0.46 * vm.canvasHeight,
          });
          img.scaleToHeight(580);
          img.selectable = false;
          vm.canvas.add(img);
          vm.canvas.renderAll();
          vm.showProgressModal = false;
        }.bind(this),
        {
          crossOrigin: 'anonymous',
        }
      );
    },
    toggleQrCode() {
      const qrCodeUrl = this.packageNames.qrCode;
      let vm = this;

      if (this.pageData.qrCodeVisibility) {
        this.showProgressModal = true;
        const qrCode = this.getFabricObjectFromId('qrCode', vm.canvas.getObjects());

        if (!qrCode) {
          fabric.Image.fromURL(
            qrCodeUrl,
            function (img) {
              img.set({
                id: 'qrCode',
                originX: 'center',
                originY: 'center',
                left: 0.82 * vm.canvasWidth,
                top: 0.86 * vm.canvasHeight,
              });
              img.selectable = false;
              vm.canvas.add(img);
              img.scaleToWidth(80);
              vm.canvas.renderAll();
              vm.resetFeatures();
              vm.showProgressModal = false;
            }.bind(this),
            {
              crossOrigin: 'anonymous',
            }
          );
        }
      } else {
        const qrCode = this.getFabricObjectFromId('qrCode', vm.canvas.getObjects());

        if (qrCode) {
          vm.canvas.remove(qrCode);
          vm.canvas.renderAll();
          vm.resetFeatures();
        }
      }
    },
    getFabricObjectFromId(id, objects) {
      for (const item of objects) {
        if (item.id == id) {
          return item;
        }
      }

      return null;
    },
    setTitle(text) {
      let vm = this;
      const prevTitle = this.getFabricObjectFromId('title', vm.canvas.getObjects());

      if (prevTitle) {
        vm.canvas.remove(prevTitle);
        vm.canvas.renderAll();
      }

      const fabText = new fabric.Text(text, {
        id: 'title',
        fontSize: 36,
        originX: 'center',
        originY: 'center',
        left: 0.5 * this.canvasWidth,
        top: 0.14 * this.canvasHeight,
        fill: this.pageData.fontColor.hex8,
      });
      vm.canvas.add(fabText);
      vm.canvas.renderAll();
    },
    setSubTitle(text) {
      let vm = this;

      const prevSubtitle = this.getFabricObjectFromId('subTitle', vm.canvas.getObjects());

      if (prevSubtitle) {
        vm.canvas.remove(prevSubtitle);
        vm.canvas.renderAll();
      }

      const fabText = new fabric.Text(text, {
        id: 'subTitle',
        fontSize: 28,
        underline: true,
        originX: 'center',
        originY: 'center',
        left: 0.5 * this.canvasWidth,
        top: 0.78 * this.canvasHeight,
        fill: this.pageData.fontColor.hex8,
      });
      vm.canvas.add(fabText);
      vm.canvas.renderAll();
    },
    toggleAndMoreVisibility() {
      let vm = this;

      if (this.pageData.andMoreVisibility) {
        const fabText = new fabric.Text('And More!'.toUpperCase(), {
          id: 'andMore',
          fontSize: 20,
          originX: 'center',
          originY: 'center',
          left: 0.5 * this.canvasWidth,
          top: 0.92 * this.canvasHeight,
          fill: this.pageData.fontColor.hex8,
        });
        vm.canvas.add(fabText);
        vm.canvas.moveTo(fabText, vm.canvas.getObjects().length);
        vm.canvas.renderAll();
      } else {
        const fabText = this.getFabricObjectFromId('andMore', vm.canvas.getObjects());

        if (fabText) {
          vm.canvas.remove(fabText);
          vm.canvas.renderAll();
        }
      }
    },
    setFeature(index, name, iconData, visible) {
      let vm = this;
      try {
        const fabricObj = this.getFabricObjectFromId(`feature${index}`, vm.canvas.getObjects());

        if (fabricObj) {
          vm.canvas.remove(fabricObj);
          vm.canvas.renderAll();
        }

        if (!visible) {
          return;
        }
      } catch (e) {
        console.error(e);
      }

      let feature_group = [];
      const fabText = new fabric.Textbox(name, {
        id: name,
        fontSize: 20,
        originX: 'center',
        originY: 'center',
        fill: this.pageData.fontColor.hex8,
        splitByGrapheme: false,
        textAlign: 'center',
        width: 150,
      });
      fabText.selectable = false;

      feature_group.push(fabText);

      fabric.loadSVGFromString(iconData.svg, (obj, options) => {
        let svg = fabric.util.groupSVGElements(obj, options);
        svg.set('originX', 'center');
        svg.set('originY', 'center');
        svg.set('fill', this.pageData.fontColor.hex8);
        svg.selectable = false;

        if (svg.height > svg.width) {
          svg.scaleToHeight(32);
        } else {
          svg.scaleToWidth(32);
        }
        feature_group.push(svg);
      });

      const fabGroup = new fabric.Group(feature_group);
      fabText.set('top', 30);
      feature_group[1].set('top', 0);
      fabGroup.set('top', 0.83 * vm.canvasHeight);
      fabGroup.set('height', 30);
      fabGroup.set('width', 150);
      fabGroup.set('id', `feature${index}`);
      fabGroup.selectable = false;

      const gap = 0.22;
      let startPosition = 0.1;

      if (!this.pageData.qrCodeVisibility) {
        startPosition = 0.2;
      }

      switch (index) {
        case 0:
          fabGroup.set('left', startPosition * vm.canvasWidth);
          break;
        case 1:
          fabGroup.set('left', (startPosition + gap) * vm.canvasWidth);
          break;
        case 2:
          fabGroup.set('left', (startPosition + gap * 2) * vm.canvasWidth);
          break;
      }

      vm.canvas.add(fabGroup);
      vm.canvas.renderAll();
    },
    async save() {
      if (!this.pageData.bgUrl) {
        this.toast.message = 'Please select background image first before saving';
        this.showToast('danger');
        return;
      }
      const db = firebase.database();
      const dbRef = db.ref(`resort-navigator/location-data/${this.getCampgroundKey}/marketing-asset-editor`);
      try {
        const { borderColor, backgroundOverlayColor, fontColor, ...others } = this.pageData;
        await dbRef.set({
          borderColor: borderColor.hex8,
          backgroundOverlayColor: backgroundOverlayColor.hex8,
          fontColor: fontColor.hex8,
          ...others,
        });
        this.toast.message = 'Saved!';
        this.showToast();
      } catch (e) {
        console.error('Something went wrong while saving data: ' + e);
      }
    },
    async downloadCanvas() {
      var image = this.canvas.toDataURL({
        format: 'png',
      });
      var link = document.createElement('a');
      link.download = 'marketing-asset.png';
      link.href = image;
      link.click();
    },
    async saveAndDownload() {
      this.save();
      this.downloadCanvas();
    },
  },
  components: {
    'chrome-picker': Chrome,
    IconList,
  },
};
</script>
<style scoped>
.default-margin-top {
  margin-top: 16px;
}

.margin-top-half {
  margin-top: 8px;
}

.default-padding {
  padding: 16px;
}

.dropdown-used button:first-child {
  color: #fff;
  background-color: #0c95de;
  border-color: #0b8dd2;
}

#map-edit canvas {
  width: 100%;
  height: 100%;

  background-repeat: no-repeat;
  background-size: contain;
}

#map-edit .color-selector div {
  width: 20px;
  height: 20px;
}

#map-edit .color-selector .btn {
  padding: 0.25rem 0.25rem;
}

#map-edit .vc-chrome {
  width: 175px;
  position: fixed;
  z-index: 2;
  cursor: pointer;
}

#map-edit #button-bar button.btn {
  padding: 5px;
}

#map-edit #button-bar label {
  margin: 0px;
}

#map-edit #button-bar .color-selector div {
  width: 15px;
  height: 15px;
}

#map-edit #button-bar .dropdown .btn:not(.dropdown-toggle) {
  padding: 0px 0px 0px 5px;
}

div.two-column {
  display: grid;
  grid-template-columns: auto 1fr;
}

.multiselect input {
  min-width: 0px;
}

.button-header {
  min-width: 1000px;
}

.form-check-input {
  min-width: 28px !important;
}
</style>
