<template>
  <div :class="!embedded ? 'p-2 form-container' : ''">
    <form @submit.prevent="submitUpdate" novalidate>
      <div class="mb-3" v-if="allowVideo">
        <label class="form-label">Header Type</label>
        <div class="row">
          <div class="col">
            <input
              class="form-check-input"
              type="radio"
              id="header-type-images"
              name="header-type"
              value="images"
              v-model="headerType"
              @change="() => (this.headerType = 'images')" />
            <label class="form-check-label" for="header-type-images">Image(s)</label>
          </div>
          <div class="col">
            <input
              class="form-check-input"
              type="radio"
              id="header-type-video"
              name="header-type"
              value="video"
              v-model="headerType"
              @change="() => (this.headerType = 'video')" />
            <label class="form-check-label" for="header-type-video">Video</label>
          </div>
        </div>
      </div>
      <div v-if="headerType == 'video'">
        <div class="mb-3">
          <label class="form-label">YouTube Video Code/URL</label>
          <input class="form-control" v-model="headerInfo.videoURL" type="text" />
          <small class="form-text text-muted"
            >This is the code at the end of a YouTube video. Example: https://www.youtube.com/watch?v=47Je-wWlJ5U the code would be 47Je-wWlJ5U</small
          >
        </div>

        <!--b-form-radio-group v-model="headerInfo.videoPlaybackMode" label="Playback Mode">
            <b-row>
              <b-col>
                <b-form-group description="Video will automatically start, but audio will start muted" class="mt-0">
                  <b-form-radio value="autoplay">
                    Autoplay
                  </b-form-radio>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  description="Video will be paused at the start, but once the user hits play, audio will be included by default"
                  class="mt-0"
                >
                  <b-form-radio value="audioon">
                    Audio On
                  </b-form-radio>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form-radio-group-->
      </div>
      <div v-else-if="headerType == 'images'">
        <div v-if="placeId">
          <button id="btn-crop" class="btn m-1 btn-primary" type="button" @click.prevent="getGooglePhotos()">
            Select Image(s) From Google Places
          </button>
        </div>
        <HeaderAttachments
          :title="title"
          :id="id"
          label="Upload Images"
          :attachments="attachments"
          bucket="headers"
          :uploadMultiple="!singleImage"
          :image-only="imageOnly"
          acceptedFiles="image/png,image/jpeg,image/gif"
          @fileAdded="updateInput"
          ref="headerAttachments"
          :height="height"
          :width="width" />
        <div class="mb-3">
          <label class="form-label">Slideshow Delay</label>
          <div class="row">
            <div class="col-6">
              <input class="form-control" v-model.number="headerInfo.delaySeconds" type="number" step="1" />
            </div>
          </div>
          <small class="form-text text-muted">Time in seconds the slideshow will delay between images.</small>
        </div>
      </div>
      <div class="mb-3">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="hideTitle" v-model="headerInfo.hideTitle" />
          <label class="form-check-label" for="hideTitle">Hide Title in Android</label>
        </div>
        <small class="form-text text-muted">Checking this hide the title of this item across the top of your header on Android phones.</small>
      </div>

      <Validation :validator="isRequired && invalid" feedback="Image is required." />

      <template v-if="!embedded">
        <button class="btn m-1 btn-primary" type="submit">Submit</button>
        <button class="btn m-1 btn-secondary" type="button" @click.stop="cancelUpdate">Cancel</button>
      </template>
    </form>
    <b-modal
      id="image-selection"
      ref="imagesModal"
      title="Select Google Image(s)"
      size="lg"
      :buttons="[
        { title: 'Cancel', variant: 'btn-secondary' },
        { title: 'Add Images', variant: 'btn-accent', action: () => addImages() },
      ]">
      <!-- <b-modal ref="image-modal" id="image-selection" scrollable size="lg" title="Select Google Image(s)" ok-title="Add Images" @ok="addImages"> -->
      <template #body>
        <div id="photo-options" class="photo-options-container">
          <div v-for="(item, index) in photos" :key="index" class="photo-checkbox form-container">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" :id="'photo-option-' + index" :value="item.getUrl()" v-model="photosSelected" />
              <label class="form-check-label" :for="'photo-option-' + index">
                <img :src="item.getUrl()" :class="{ 'photo-options': true }" />
              </label>
            </div>
          </div>
        </div>
        <div>
          Images above are retrieved from Google places. If you want these modified, find instructions
          <a href="https://support.google.com/business/answer/6190090?hl=en" class="instruction-link" target="_blank">here.</a>
        </div>
      </template>
    </b-modal>
    <div hide ref="photos" />
  </div>
</template>

<script>
import HeaderAttachments from '../HeaderAttachments';
import { NonDetailFormMixin } from '../mixins/NonDetailFormMixin.js';
import axios from 'axios';
import BModal from '@/components/common/BModal.vue';

export default {
  components: {
    HeaderAttachments,
    BModal,
  },
  mixins: [NonDetailFormMixin],
  props: {
    id: {
      type: String,
      default: 'header-images',
    },
    value: Object,
    dbContext: String,
    embedded: Boolean,
    placeId: String,
    title: String,
    allowVideo: { type: Boolean, default: true },
    singleImage: { type: Boolean, default: false },
    imageOnly: { type: Boolean, default: false },
    height: {
      type: Number,
      default: 200,
    },
    width: {
      type: Number,
      default: 375,
    },
    isRequired: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['header-info-update'],
  data() {
    return {
      attachments: [],
      photosSelected: [],
      photos: [],
      headerType: '',
      headerInfo: {
        images: [],
        videoURL: null,
        videoPlaybackMode: 'autoplay',
        delaySeconds: 4,
        hideTitle: false,
      },
      invalid: false,
      collisionID: 0,
    };
  },
  watch: {
    'headerInfo.videoURL': function () {
      if (this.headerInfo.videoURL)
        this.$nextTick(() => {
          this.$set(
            this.headerInfo,
            'videoURL',
            this.headerInfo.videoURL.replace('https://www.youtube.com/watch?v=', '').replace('https://youtu.be/', '').split('&')[0].split('?')[0]
          );
          this.updateInput();
        });
    },
    value: function (newVal, oldVal) {
      if (!newVal) this.headerInfo = [];
      if (((!oldVal || Object.keys(oldVal).length == 0) && newVal && Object.keys(newVal).length > 0) || !newVal)
        this.setupHeaderInfo(this.headerType);
    },
    headerType: function () {
      if (this.headerType === 'video') this.headerInfo.videoPlaybackMode = this.headerInfo.videoPlaybackMode || 'autoplay';
      this.updateInput();
    },
    headerInfo: {
      handler: function (newVal, oldVal) {
        this.$emit('header-info-update', newVal);
        //newVal.delaySeconds = newVal.delaySeconds || null;
        const needsEmitted =
          !newVal.images ||
          newVal.images.length == 0 ||
          newVal.images.reduce((acc, image) => {
            return acc ? acc : image.fileInfo == undefined || image.fileInfo == 100;
          }, false);

        if (this.embedded && newVal.images === oldVal.images && needsEmitted) this.$emit('input', this.updateHeaderFromType(newVal.images));
      },
      deep: true,
    },
    /* attachments: {
      handler: function() {
        if (this.embedded) this.$emit('input', this.updateHeaderFromType());
      },
      deep: true,
    },
    */
  },
  created() {
    this.setupHeaderInfo();
  },
  methods: {
    setupHeaderInfo: async function (headerType) {
      this.headerInfo =
        this.value && Object.keys(this.value).length > 0
          ? this.value
          : this.dbContext
          ? (await this.getContextRef(`header-info/${this.dbContext}`).once('value')).val() || this.headerInfo
          : this.headerInfo;
      this.headerInfo.images = Array.isArray(this.headerInfo.images) ? this.headerInfo.images : [];
      this.attachments = this.headerInfo.images;
      this.attachments.forEach((attachment) => {
        attachment.originalURL = attachment.originalURL || attachment.imageURL;
        attachment.imageName = attachment.imageName || `image_${Math.floor(Date.now() / 1000)}.jpg`;
        attachment.radio = attachment.radio || 'Original';
        attachment.date = attachment.date || Date.now();
      });
      this.headerType = (this.headerInfo.videoURL ? 'video' : 'images') || headerType;
      console.log('🚀 ~ this.headerType:', this.headerType);
    },
    updateHeaderImage: function (imageURL) {
      this.attachments = [imageURL];
    },
    getGooglePhotos() {
      // eslint-disable-next-line no-undef
      const placesService = new google.maps.places.PlacesService(this.$refs.photos);
      placesService.getDetails({ placeId: this.placeId }, (results) => {
        this.photos = results.photos;
        this.$refs.imagesModal.show();
      });
    },
    async addImages() {
      let promises = [];
      this.photosSelected.map((photo) => {
        promises.push(
          new Promise((resolve) => {
            this.copyImage(photo).then(resolve);
          })
        );
      });
      await Promise.all(promises).then(() => this.updateInput());
    },
    async copyImage(imageURL) {
      this.$refs.headerAttachments.receivedAttach(imageURL);
      const url = `${this.$hostname}/api/copyImage`;
      const newDrawable = (await axios.post(url, { url: imageURL, communityKey: this.communityKey || this.getCampgroundKey, bucket: this.bucket }))
        .data.url;

      //Create unix timestamp for image name
      this.collisionID++;
      const timestamp = Math.floor(Date.now()); // Unix timestamp in seconds
      const item = { name: `image_${timestamp}${this.collisionID}.jpg`, newDrawableUrl: newDrawable };
      console.log('item name!', item.name, this.collisionID);

      this.$refs.headerAttachments.received(item, imageURL);
      //this.headerInfo.images.push({ imageURL: newDrawable, originalURL: newDrawable, radio: 'Original' });
    },
    updateHeaderFromType(value) {
      const attachments = value || this.attachments;
      if ((this.headerType === 'video' && !this.headerInfo.videoURL) || (this.headerType === 'images' && attachments.length == 0)) return null;
      return {
        ...this.headerInfo,
        ...(this.headerType === 'video'
          ? { images: [], delaySeconds: null }
          : {
              // eslint-disable-next-line no-unused-vars
              images: attachments.map(({ fileInfo, ...attachment }) => attachment).filter((attachment) => attachment),
              videoURL: null,
              videoPlaybackMode: null,
            }),
      };
    },
    async isValid() {
      this.invalid = !(await this.$refs.headerAttachments?.isInvalid());
      return this.invalid;
    },
    async submitUpdate() {
      if (await this.isValid()) {
        if (this.headerType !== 'video') {
          const headerInfo = this.updateHeaderFromType();
          this.getContextRef(`header-info/${this.dbContext}`).set(headerInfo);
          this.exitEdit('submit');
        } else {
          const headerInfo = this.updateHeaderFromType();
          this.getContextRef(`header-info/${this.dbContext}`).set(headerInfo);
          this.exitEdit('submit');
        }
      }
    },
    updateInput: function () {
      if (this.embedded) this.$emit('input', this.updateHeaderFromType());
    },
  },
};
</script>

<style lang="scss" scoped>
.photo-options-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
}

.photo-options {
  height: 68px;
  width: 100px;
  object-fit: cover;
  cursor: pointer;
}

.photo-checkbox {
  height: 75px;
  width: 130px;
}

.instruction-link {
  text-decoration: none;
}
</style>
