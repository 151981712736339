<template>
  <div id="login-page" class="w-100 text-center text-white">
    <img src="/amc_logo_large.png" alt="" id="amc-logo" @click="goHome()" />
    <div id="content-column">
      <div class="login" v-if="!passwordReset && checkForEmailLinkComplete">
        <h2 id="sign-in">Sign In</h2>
        <div v-if="isEmailLinkAvailable">
          <input type="text" v-model="email" placeholder="Email" /><br />
          <ul class="list-unstyled">
            <li class="firebaseui-list-item">
              <button
                @click="sendEmailLink"
                class="firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised firebaseui-idp-password firebaseui-id-idp-button"
                data-provider-id="password"
                data-upgraded=",MaterialButton">
                <span class="firebaseui-idp-icon-wrapper">
                  <img class="firebaseui-idp-icon" alt="" src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/mail.svg" />
                </span>
                <span class="firebaseui-idp-text firebaseui-idp-text-long">Send email link</span>
                <span class="firebaseui-idp-text firebaseui-idp-text-short">Email</span>
              </button>
            </li>
          </ul>
        </div>
        <div v-else>
          <h2>Google</h2>
          <ul class="list-unstyled">
            <li>
              <button
                @click="googleLogin"
                class="firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised firebaseui-idp-google firebaseui-id-idp-button"
                data-provider-id="google.com"
                data-upgraded=",MaterialButton">
                <span class="firebaseui-idp-icon-wrapper">
                  <img class="firebaseui-idp-icon" alt="" src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" />
                </span>
                <span class="firebaseui-idp-text firebaseui-idp-text-long">Sign in with Google</span>
                <span class="firebaseui-idp-text firebaseui-idp-text-short">Google</span>
              </button>
            </li>
          </ul>
          - or -
          <h2>Email</h2>
          <input type="text" v-model="email" placeholder="Email" /><br />
          <input type="password" v-model="password" placeholder="Password" /><br />
          <ul class="list-unstyled">
            <li class="firebaseui-list-item">
              <button
                @click="login"
                class="firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised firebaseui-idp-password firebaseui-id-idp-button"
                data-provider-id="password"
                data-upgraded=",MaterialButton">
                <span class="firebaseui-idp-icon-wrapper">
                  <img class="firebaseui-idp-icon" alt="" src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/mail.svg" />
                </span>
                <span class="firebaseui-idp-text firebaseui-idp-text-long">Sign in with email</span>
                <span class="firebaseui-idp-text firebaseui-idp-text-short">Email</span>
              </button>
            </li>
          </ul>
          <div id="forgot-password">
            <a href="#" @click="passwordReset = true">Forgot Password?</a>
          </div>
        </div>
      </div>
      <div v-else class="login">
        <h2 id="reset-password">Processing Login</h2>
        <input type="text" v-model="email" placeholder="Email" /><br />
        <button
          @click="resetPassword"
          class="firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised firebaseui-idp-password firebaseui-id-idp-button"
          data-provider-id="password"
          data-upgraded=",MaterialButton">
          <span class="firebaseui-idp-icon-wrapper">
            <img class="firebaseui-idp-icon" alt="" src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/mail.svg" />
          </span>
          <span class="firebaseui-idp-text firebaseui-idp-text-long">Reset Password</span>
          <span class="firebaseui-idp-text firebaseui-idp-text-short">Email</span>
        </button>
      </div>
    </div>

    <b-modal
      id="login-modal"
      ref="modal"
      :title="modalTitle"
      size="lg"
      :buttons="[{ title: 'Dismiss', variant: 'btn-secondary', action: () => dismissModal() }]">
      <template #body>
        <div v-if="loadingLink" class="loading-item">
          <div class="spinner-border text-primary m-5" style="width: 4rem; height: 4rem" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div v-else v-html="modalHTML"></div>
      </template>
    </b-modal>
    <div id="zsiqwidget"></div>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/functions';
import { LoginMixin } from '../components/mixins/LoginMixin.js';
import BModal from '../components/common/BModal.vue';

export default {
  name: 'login',
  components: {
    BModal,
  },
  mixins: [LoginMixin],
  data() {
    return {
      error: null,
      success: false,
      modalHTML: '',
      modalTitle: 'Reset Password',
      email: '',
      password: '',
      passwordReset: false,
      isEmailLinkAvailable: false,
      checkForEmailLinkComplete: false,
      loadingLink: false,
    };
  },
  created: function () {
    if (this.getFeatureAvailability('useEmailLink')) {
      this.isEmailLinkAvailable = true;
      this.checkForEmailLinkComplete = true;
    } else {
      firebase
        .database()
        .ref('resort-navigator')
        .child('amc-dollars/config/enabled')
        .once('value')
        .then((snapshot) => {
          console.log('🚀 ~ file: LoginMixin.js ~ line 52 ~ .then ~ snapshot', snapshot.val());
          window.localStorage.setItem('DOLLARS_ENABLED', snapshot.val() || false);
          this.isEmailLinkAvailable = snapshot.val() || false;
          this.checkForEmailLinkComplete = true;
        })
        .catch((err) => {
          console.log('🚀 ~ file: LoginMixin.js ~ line 52 ~ .then ~ snapshot catch', err);
          window.localStorage.setItem('DOLLARS_ENABLED', false);
          this.checkForEmailLinkComplete = true;
        });
    }
    if (this.$route.query.action == 'logout') {
      this.logout();
    }
  },
  mounted() {
    window.$zoho = window.$zoho || {};
    window.$zoho.salesiq = window.$zoho.salesiq || {
      widgetcode: '7959affddcac5507cb64242a9a9db6d8e92b82ddb6a39fcfb6560d1d7d77c44b761cd6989df461283b52601217c64533',
      values: {},
      ready: function () {},
    };
    var d = document;
    var s = d.createElement('script');
    s.type = 'text/javascript';
    s.id = 'zsiqscript';
    s.defer = true;
    s.src = 'https://salesiq.zoho.com/widget';
    var t = d.getElementsByTagName('script')[0];
    t.parentNode.insertBefore(s, t);
  },
  methods: {
    logout: function () {
      this.unsetCampgroundLocationData();
      window.localStorage.removeItem('CAMPGROUND_KEY');
      firebase.auth().signOut();
    },
    login: function () {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(async (result) => {
          this.authorizeUser(result.user.uid, true);
        })
        .catch((err) => {
          this.$message.create({
            title: 'Login Error',
            body: err.message,
            classes: 'amc-modal',
            buttons: [this.$message.button('OK')],
          });
        });
    },
    googleLogin: function () {
      const provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(async (result) => {
          this.authorizeUser(result.user.uid, true);
        })
        .catch((err) => {
          alert(err.message);
        });
    },
    sendEmailLink: function () {
      this.loadingLink = true;
      this.$refs.modal.show();
      const getUserData = firebase.functions().httpsCallable('getUserData');
      getUserData({ type: 'isUserAdmin', email: this.email })
        .then((isAdmin) => {
          if (isAdmin.data) {
            getUserData({ type: 'loginLink', email: this.email, template: 'sendEmailLinkAdmin', vueHost: this.$vueHost }).then(() => {
              this.loadingLink = false;
              window.localStorage.setItem('emailForSignIn', this.email);
              this.modalHTML = `You have been sent an email to ${this.email} with your login link.<br/></br> If you do not receive it shortly, please check your spam folder or contact your administrator.<br/><br/>You may close this window/tab.`;
            });
          } else {
            this.loadingLink = false;
            this.modalHTML = `The email address ${this.email} does not have access to this site. Please contact your administrator for more information.`;
          }
        })
        .catch(() => {
          this.loadingLink = false;
          this.modalHTML = `The email address ${this.email} does not have access to this site. Please contact your administrator for more information.`;
        });
    },
    resetPassword: function () {
      firebase
        .auth()
        .sendPasswordResetEmail(this.email)
        .then(() => {
          this.modalHTML =
            '<div>Your password has been reset, please check your e-mail for further instructions.<br /><br/>If you do not receive an email, please check your spam folder.</div>';
          this.success = true;
          this.$refs.modal.show();
        })
        .catch((err) => {
          this.modalHTML = `<div>Error resetting your password.<br /><br/>${err.message}</div>`;
          this.$refs.modal.show();
          console.log(err);
        });
    },
    dismissModal: function () {
      if (this.success) {
        window.location.reload();
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  display: block;
}

#sign-in,
#reset-password,
#forgot-password a:link,
#forgot-password a:visited {
  color: #01f2a6;
}
#forgot-password {
  width: 100%;
  text-align: right;
}

input {
  min-width: 64px;
  padding: 0 16px;
}
#login-page {
  background-color: transparent;
  background-image: linear-gradient(180deg, #0a141d 0%, #183449 26%);
  min-height: 100vh;
}
.login {
  background-color: transparent;
  background-image: linear-gradient(40deg, rgba(29, 169, 243, 0.3) 0%, #1da9f3 100%);
  width: 300px;
  border-radius: 12px;
  margin: auto;
  padding: 30px;
}
ul li .firebaseui-idp-google,
.firebaseui-idp-google:hover,
.mdl-button.firebaseui-idp-google:active,
.mdl-button.firebaseui-idp-google:focus {
  background-color: #fff;
}
ul li .firebaseui-idp-password,
.firebaseui-idp-password:hover,
.mdl-button.firebaseui-idp-password:active,
.mdl-button.firebaseui-idp-password:focus {
  background-color: #1da9f3;
  color: black;
}
.firebaseui-idp-button {
  direction: ltr;
  font-weight: 500;
  height: auto;
  line-height: normal;
  max-width: 220px;
  min-height: 40px;
  padding: 8px 16px;
  text-align: left;
  width: 100%;
}
.mdl-button--raised {
  background: rgba(158, 158, 158, 0.2);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.mdl-button {
  background: 0 0;
  border: none;
  border-radius: 2px;
  color: #000;
  position: relative;
  height: 36px;
  margin: 0;
  min-width: 64px;
  padding: 0 16px;
  display: inline-block;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 0;
  overflow: hidden;
  will-change: box-shadow;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  outline: 0;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  line-height: 36px;
  vertical-align: middle;
}
.firebaseui-idp-icon-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.firebaseui-idp-icon {
  border: none;
  display: inline-block;
  height: 18px;
  vertical-align: middle;
  width: 18px;
}
.firebaseui-idp-google > .firebaseui-idp-text {
  color: #757575;
}

.firebaseui-idp-text.firebaseui-idp-text-long {
  display: table-cell;
}
.firebaseui-idp-text {
  color: #fff;
  display: table-cell;
  font-size: 14px;
  padding-left: 16px;
  text-transform: none;
  vertical-align: middle;
}

.firebaseui-idp-text.firebaseui-idp-text-short {
  display: none;
}

#login-modal .modal-content {
  background-color: #0c1a25;
  background-image: linear-gradient(40deg, rgba(29, 169, 243, 0.3) 0%, #1da9f3 100%);
  /* width: 300px; */
  border-radius: 12px;
  margin: auto;
  /* padding: 30px; */
  color: #01f2a6;
}

#login-modal .modal-content .modal-body {
  color: white;
}

.loading-item {
  height: 100%;
  width: 100%;
  text-align: center;
}

#content-column {
  display: block !important;
}
</style>
