<template>
  <div class="mb-3">
    <label class="form-label">{{ label || 'Upload Files' }}</label>
    <small class="form-text text-muted">{{ description }}</small>
    <div v-if="uploadMultiple || attachments.length == 0">
      <vue-dropzone
        :ref="this.id + 'myVueDropzone'"
        :include-styling="false"
        :useCustomSlot="true"
        :id="this.id + 'myVueDropzone'"
        :class="this.id"
        @vdropzone-upload-progress="uploadProgress"
        :options="dropzoneOptions"
        @vdropzone-file-added="fileAdded"
        @vdropzone-success-multiple="success">
        <div class="dropzone-container">
          <div class="file-selector">
            <figure>
              <svg
                width="104px"
                height="104px"
                viewBox="0 0 104 90"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <defs>
                  <circle id="path-1" cx="36" cy="36" r="36"></circle>
                  <filter x="-37.5%" y="-29.2%" width="175.0%" height="175.0%" filterUnits="objectBoundingBox" id="filter-2">
                    <feOffset dx="0" dy="6" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                    <feGaussianBlur stdDeviation="8" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                    <feColorMatrix
                      values="0 0 0 0 0.0117647059   0 0 0 0 0.0862745098   0 0 0 0 0.160784314  0 0 0 0.08 0"
                      type="matrix"
                      in="shadowBlurOuter1"
                      result="shadowMatrixOuter1"></feColorMatrix>
                    <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter2"></feOffset>
                    <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter2" result="shadowBlurOuter2"></feGaussianBlur>
                    <feColorMatrix
                      values="0 0 0 0 0.0117647059   0 0 0 0 0.0862745098   0 0 0 0 0.160784314  0 0 0 0.11 0"
                      type="matrix"
                      in="shadowBlurOuter2"
                      result="shadowMatrixOuter2"></feColorMatrix>
                    <feMerge>
                      <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                      <feMergeNode in="shadowMatrixOuter2"></feMergeNode>
                    </feMerge>
                  </filter>
                </defs>
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="Artboard" transform="translate(-460.000000, -125.000000)">
                    <g id="Group-4" transform="translate(412.000000, 129.000000)">
                      <g id="Group-2" transform="translate(58.000000, 0.000000)">
                        <circle id="Oval" fill="#01F2A6" opacity="0.200000001" cx="42" cy="42" r="42"></circle>
                        <g id="Group" transform="translate(6.000000, 6.000000)">
                          <g id="Oval">
                            <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"></use>
                            <use fill="#FFFFFF" fill-rule="evenodd" xlink:href="#path-1"></use>
                            <use fill="#1DA9F330" fill-rule="evenodd" xlink:href="#path-1"></use>
                          </g>
                          <g
                            id="upload-cloud"
                            transform="translate(21.818182, 24.000000)"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2">
                            <polyline
                              id="Path"
                              stroke="#000000"
                              points="19.6458087 17.3789847 14.3565525 12.0897285 9.06729634 17.3789847"></polyline>
                            <path d="M14.3565525,12.0897285 L14.3565525,24.1794569" id="Path" stroke="#01F2A6"></path>
                            <path
                              d="M25.6438239,20.7792208 C28.2965835,19.3021499 29.6312816,16.1761528 28.8860265,13.1856562 C28.1407715,10.1951596 25.5052337,8.10125672 22.4838689,8.09921935 L20.8179512,8.09921935 C19.7219904,3.76967373 16.1275086,0.577339516 11.7773112,0.0700384831 C7.42711383,-0.43726255 3.22057026,1.84535014 1.19724759,5.81113853 C-0.826075091,9.77692693 -0.247870665,14.6059952 2.6515151,17.9569414"
                              id="Path"
                              stroke="#01F2A6"></path>
                            <polyline
                              id="Path"
                              stroke="#01F2A6"
                              points="19.6458087 17.3789847 14.3565525 12.0897285 9.06729634 17.3789847"></polyline>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </figure>
            <div>Click Here or Drop To Add {{ this.uploadMultiple ? 'Files' : 'File' }}</div>
            <!--div class="seperator"> or </div>
            <button class="btn m-1 btn-secondary" type="button">Browse</button-->
          </div>
        </div>
      </vue-dropzone>
      <button id="btn-external-photos" class="btn btn-primary m-1" type="button" @click="$refs[`${id}-externalPhotos`].show()">
        Find Stock Image
      </button>
      <button type="button" id="btn-upload" class="btn btn-primary m-2" @click="() => $refs.imagesAI.showModal()">Generate Image</button>

      <ExternalPhotos
        :id="`${id}-externalPhotos`"
        :ref="`${id}-externalPhotos`"
        :bucket="bucket"
        :title="title"
        @show-spinner="(val) => (val ? $refs.overlay.show() : $refs.overlay.hide())"
        @set-attach="receivedAttach"
        @selected-image="received" />
      <ImagesAI ref="imagesAI" :bucket="bucket" @set-attach="receivedAttach" @ai-image="received" />
    </div>
    <HeaderAttachmentList :id="id" :attachments="attachments" :height="height" :width="width" :image-only="imageOnly" :dirty="dirty" />

    <Overlay ref="overlay" :timeout="15000" />
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import HeaderAttachmentList from './HeaderAttachmentList';
import ExternalPhotos from '@/components/modals/ExternalPhotosModal';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { BlurImageMixin } from './mixins/BlurImageMixin';
import ImagesAI from './modals/ImagesAIModal.vue';
import Overlay from './common/Overlay.vue';

export default {
  name: 'Attachment',
  components: {
    vueDropzone: vue2Dropzone,
    HeaderAttachmentList,
    ExternalPhotos,
    ImagesAI,
    Overlay,
  },
  props: {
    attachments: Array,
    label: String,
    bucket: String,
    fullBucket: Boolean,
    uploadMultiple: Boolean,
    imageOnly: {
      type: Boolean,
      default: false,
    },
    id: String,
    acceptedFiles: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    height: {
      type: Number,
      default: 200,
    },
    width: {
      type: Number,
      default: 375,
    },
    communityKey: String,
    title: String,
  },
  data() {
    const thumbnailWidth = 250;
    const thumbnailHeight = (thumbnailWidth * this.height) / this.width;
    return {
      dropzoneOptions: {
        // The Url Where Dropped or Selected files will be sent
        url: `/`,
        // File Size allowed in MB
        maxFilesize: 102400000,
        // Authentication Headers like Access_Token of your application
        headers: {
          Authorization: `Access Token`,
        },
        // The way you want to receive the files in the server
        paramName: function () {
          return 'file[]';
        },
        dictDefaultMessage: 'Upload Files Here xD',
        includeStyling: false,
        previewsContainer: false,
        thumbnailWidth,
        thumbnailHeight,
        uploadMultiple: false,
        parallelUploads: 20,
        clickable: '#btn-upload, #headermyVueDropzone',
      },
      date: 0,
      imageBucket: 'reservations',
      progressMap: {},
      isLoadingExternal: false,
      externalAttach: {},
      dirty: false,
    };
  },
  created() {
    this.dropzoneOptions.clickable = `.${this.id}`;
    this.dropzoneOptions.acceptedFiles = this.acceptedFiles || null;
    this.dropzoneOptions.uploadMultiple = this.uploadMultiple == true;
    const bucket = this.bucket || 'misc';
    this.date = new Date().getTime().toString();
    this.imageBucket =
      this.fullBucket && this.bucket
        ? firebase.storage().ref(bucket)
        : firebase
            .storage()
            .ref()
            .child(this.communityKey || this.getCampgroundKey)
            .child('images')
            .child(bucket)
            .child(this.date);
  },
  computed: {
    getNewImageClass: function () {
      const classes = { btn: true, 'm-1': true, 'btn-primary': true };
      classes[this.id] = true;
      return classes;
    },
  },
  methods: {
    receivedAttach(key = 0) {
      this.$refs.overlay.show();
      this.externalAttach[key] = this.createAttachmentObject();
      this.externalAttach[key].key = key;
      this.attachments.push(this.externalAttach[key]); //Push latest attachment to attachments array
    },
    async received(item, externalKey = 0) {
      console.log('received: ', item);
      const attachment = this.externalAttach[externalKey];
      attachment.imageURL = item.newDrawableUrl;
      attachment.originalURL = attachment.imageURL;

      attachment.fileInfo.title = item.name;
      attachment.imageName = item.name;
      attachment.fileInfo._id = new Date().toISOString();
      attachment.fileInfo.extension = item.name.slice(item.name.indexOf('.'));
      console.log('created attachment: ', attachment);

      await this.retrieveImage2(attachment.imageURL).then(async (blurred) => {
        if (blurred) {
          await this.uploadFile(attachment, item.name.replace(/(\.\w+)$/, '_blur$1'), this.dataToBlob(blurred), (url) => {}, true).then((url) => {
            this.externalAttach[externalKey].blurredURL = url;
            this.$emit('fileAdded', this.externalAttach[externalKey]);
            if (this.externalAttach[externalKey].fileInfo) this.externalAttach[externalKey].fileInfo.isLoading = false;
            this.$refs.overlay.hide();
          });
        } else {
          this.$emit('fileAdded', attachment);
          attachment.fileInfo.isLoading = false;
          this.$refs.overlay.hide();
        }
      });
      //this.externalAttach.splice(externalKey, 1);
      //Remove the attachment from the externalAttach object by key
      delete this.externalAttach[externalKey];
    },
    createAttachmentObject(file) {
      const attachment = {
        radio: 'Original',
        imageName: null,
        imageURL: this.drawable,
        originalURL: null,
        croppedURL: null,
        blurredURL: null,
        caption: null,
        date: this.date,
        fileInfo: {},
      };

      const fileInfo = {
        _id: null,
        title: null,
        type: 'file',
        extension: null,
        user: JSON.parse(localStorage.getItem('user')),
        content: 'File Upload by external photos',
        source: 'external',
        thumb: null,
        thumb_list: null,
        isLoading: true,
        progress: 0,
        size: null,
      };
      //If there's a file, then it's not from an external source. Change some object properties.
      if (file) {
        attachment.imageName = file.name;
        fileInfo._id = file.upload.uuid;
        fileInfo.title = file.name;
        fileInfo.extension = '.' + file.type.split('/')[1];
        fileInfo.content = 'File Upload by Select or Drop';
        fileInfo.thumb = file.dataURL;
        fileInfo.thumb_list = file.dataURL;
        fileInfo.isLoading = true;
        fileInfo.size = file.size;
      }

      attachment.fileInfo = fileInfo;
      return attachment;
    },
    getValidMimeType(file) {
      if (!this.acceptedFiles) return true;
      return this.acceptedFiles.split(',').reduce((correctMime, mimeType) => {
        return mimeType.toLowerCase() == file.type.toLowerCase() ? true : correctMime;
      }, false);
    },
    // function called for every file dropped or selected
    async fileAdded(file) {
      let originalPromise;
      let blurredPromise;

      if (this.getValidMimeType(file)) {
        this.$refs.overlay.show();

        const attachObject = this.createAttachmentObject(file);
        const blurred = await this.retrieveImage(file);
        this.attachments.push(attachObject);

        const index = this.attachments.length - 1;
        originalPromise = new Promise((resolve) => {
          this.uploadFile(attachObject, file.name, file, (url) => {
            this.attachments[index].originalURL = url;
            this.attachments[index].imageURL = url;
            resolve();
          });
        });

        //Upload blurred photo (as "[filename]_blur.jpg"), and set blurredURL to downloadURL.
        if (blurred) {
          blurredPromise = new Promise((resolve) => {
            this.uploadFile(
              attachObject,
              file.name.replace(/(\.\w+)$/, '_blur$1'),
              this.dataToBlob(blurred),
              (url) => {
                this.attachments[index].blurredURL = url;
                resolve();
              },
              false
            );
          });
        }

        Promise.all([originalPromise, blurredPromise])
          .then(() => {
            attachObject.fileInfo.isLoading = false;
            this.$emit('fileAdded', this.attachments[index]);
            this.$refs.overlay.hide();
            console.log('fileAdded -> emitted attachment', this.attachments[index]);
          })
          .catch((error) => {
            console.log('fileAdded -> error', error);
            this.$refs.overlay.hide();
          });
      } else {
        this.$message.create({
          title: 'Incorrect Type',
          body: `Please upload files with the correct type: ${this.acceptedFiles}`,
          buttons: [this.$message.button('OK')],
        });
      }
    },
    // function where we get the upload progress
    uploadProgress() {
      //This function is empty as firebase upload is managing progress
    },
    // called on successful upload of a file
    success() {
      //This function is empty as firebase upload is managing success, all uploads fail dropzone upload.
    },
    async uploadFile(attachObject, name, file, onUpload, showProgress = true) {
      return new Promise((resolve, reject) => {
        const imageRef = this.imageBucket.child(name);
        const uploadTask = imageRef.put(file);
        this.progressMap[name] = 0;

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            let progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            this.progressMap[name] = progress;
            if (showProgress) {
              attachObject.fileInfo.progress = this.progressMap[name];
            }
          },
          function (error) {
            console.log('TCL: uploadFile -> error', error);
            reject(error); // Reject the Promise if there's an error
          },
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              attachObject.fileInfo.ref = uploadTask.snapshot.ref;
              onUpload(downloadURL);
              resolve(downloadURL); // Resolve the Promise when the upload is complete
            });
          }
        );
      });
    },
    async isInvalid() {
      console.log('🚀 ~ isInvalid ~ isInvalid:', this.attachments);
      this.dirty = true;
      await this.$nextTick();
      const invalid = this.attachments.reduce((acc, attachment) => {
        return acc || !attachment.imageURL || attachment.invalid;
      }, false);
      console.log('🚀 ~ invalid ~ invalid:', invalid);
      return invalid;
    },
  },
  mixins: [BlurImageMixin],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.file-selector {
  font-weight: 600;
  color: #4e5b69;
  z-index: -9;
  display: inline-flex;
}
figure {
  margin: 0px;
}

.file-selector div {
  align-content: center;
  display: grid;
  width: 100px;
}
.dropzone-container {
  text-align: center;
  border: 1px dashed #999;
  border-radius: 0.25rem;
  margin-bottom: 0.75rem;
}
.seperator {
  position: relative;
  padding: 0 4px;
  font-size: 12px;
}
.image-preview {
  width: 200px;
}
</style>
