<template>
  <Teleport :to="to">
    <div :id="_id" class="modal fade" tabindex="-1" role="dialog">
      <div class="modal-dialog" :class="[classes, 'modal-' + size, scrollable ? 'modal-dialog-scrollable' : '']" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <slot name="header">
              <h5 class="modal-title">{{ title }}</h5>
            </slot>
            <i class="material-icons close-button" data-bs-dismiss="modal" aria-label="Close" @click="close()"> close </i>
          </div>
          <div class="modal-body" ref="modal-body">
            <slot name="body" />
          </div>
          <div class="modal-footer">
            <slot name="footer-left" />
            <slot name="footer-right">
              <button v-for="(button, index) in buttons" :key="index" type="button" class="btn" :class="button.variant" @click="() => click(index)">
                {{ button.title }}
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
import { ref, onMounted, onUnmounted, getCurrentInstance } from 'vue';
import { Modal } from 'bootstrap';

export default {
  name: 'BModal',
  props: {
    //Modal properties
    id: { type: String, default: '' },
    classes: { type: String, default: 'amc-modal' },
    size: { type: String, default: 'md' },
    to: { type: String, default: 'body' },
    //Modal content
    title: { type: String, required: true },
    buttons: {
      type: Array,
      default: () => [
        { title: 'Cancel', variant: 'btn-secondary', action: () => {}, emit: 'cancel', hide: true },
        { title: 'OK', variant: 'btn-accent', action: () => {}, emit: 'ok', hide: true },
      ],
    },
    //Modal behavior
    scrollable: { type: Boolean, default: true },
  },
  setup(props, { emit }) {
    let _id = ref(props.id || `modal-${new Date().getTime()}`);
    let rawModal = null;
    let modal = ref(null);
    let modalBody = ref(null);

    let displayed = ref(false);
    let _debounce = ref(false);
    let _preventScroll = ref(false);

    onMounted(() => {
      //Set modal and modal body references
      rawModal = document.getElementById(_id.value);
      modal.value = new Modal(rawModal);
      modalBody.value = getCurrentInstance().refs['modal-body'];

      //Add event listeners
      modalBody.value.addEventListener('scroll', _scroll);
      rawModal.addEventListener('shown.bs.modal', _shown);
      rawModal.addEventListener('hidden.bs.modal', _hidden);

      emit('ready', { show: show, hide: hide, toggle: toggle, close: close, destroy: destroy });
    });
    onUnmounted(() => {
      modalBody.value.removeEventListener('scroll', _scroll);
      rawModal.removeEventListener('shown.bs.modal', _shown);
      rawModal.removeEventListener('hidden.bs.modal', _hidden);
    });

    //Display methods
    const show = (suppressLog) => {
      emit('show');
      modal.value.show();
      displayed.value = true;
      _preventScroll.value = false;

      if (!suppressLog) console.log(`Showing ${_id.value} modal. Sending 'show' event.`);
    };
    const hide = (suppressLog) => {
      //Emits if hidden programmatically, via cancel or confirm, escape key, or backdrop click
      emit('hide');
      modal.value.hide();
      displayed.value = false;
      _preventScroll.value = true;

      if (!suppressLog) console.log(`Hiding ${_id.value} modal. Sending 'hide' event.`);
    };
    const toggle = () => {
      emit('toggle');
      console.log(`Toggled ${_id.value} modal display to ${displayed.value}.`);

      if (displayed.value) hide(true);
      else show(true);
    };
    const close = () => {
      emit('close');
      hide(true);

      console.log(`Closed ${_id.value} modal. Sending 'close' event.`);
    };

    //Button event
    const click = (index) => {
      const button = props.buttons[index];
      if (button?.action) {
        console.log(`Clicked ${button.title} button in ${_id.value} modal. Called action and sent 'click' event.`);
        button?.action();
      } else console.log(`Clicked ${button.title} button in ${_id.value} modal. Sending 'click' event.`);

      if (button?.emit) emit(button.emit);
      else emit('click', index);

      if (button?.hide === false) return;
      hide(true);
    };

    //Utility methods
    const _scroll = () => {
      if (_preventScroll.value) return;
      if (_debounce.value) {
        clearTimeout(_debounce.value);
      }

      _debounce.value = setTimeout(() => {
        const nearBottom = modalBody.value.scrollHeight - modalBody.value.scrollTop <= modalBody.value.clientHeight + 20;
        const nearTop = modalBody.value.scrollTop <= 20;

        if (nearBottom) {
          console.log(`Scrolled to bottom of ${_id.value} modal body. Sending 'scroll-bottom' event.`);
          emit('scroll-bottom');
        }
        if (nearTop) {
          console.log(`Scrolled to top of ${_id.value} modal body. Sending 'scroll-top' event.`);
          emit('scroll-top');
        }

        console.log(`Scrolled in ${_id.value} modal body. Sending 'scroll' event.`);
        emit('scroll');
      }, 100);
    };
    const _hidden = () => {
      console.log(`Hidden ${_id.value} modal. Sending 'hidden' event.`);
      emit('hidden');
    };
    const _shown = () => {
      console.log(`Shown ${_id.value} modal. Sending 'shown' event.`);
      emit('shown');
    };

    //Destroy modal (typically only called for dynamically created modals)
    const destroy = () => {
      console.log(`Destroying ${_id.value} modal.`);
      emit('destroy');
      modal.value.dispose();
    };

    return {
      _id,
      show,
      hide,
      toggle,
      click,
      close,
      destroy,
    };
  },
};
</script>

<style scoped>
.modal {
  --bs-modal-color: #212529;
  text-align: left;
  z-index: 9999 !important;
}
.modal-body {
  height: fit-content;
}

.close-button {
  fill: #ffffff !important;
  margin-left: auto;
  cursor: pointer;
}
</style>
