<!-- eslint-disable vue/order-in-components -->
<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <div :style="getBackground">
    <FullCalendar v-if="colorBackground" ref="fullCalendar" :options="calendarOptions" :duration="{ days: duration }" :views="views" />
    <b-modal
      id="event-modal"
      ref="modal"
      :title="modalTitle"
      size="lg"
      classes="amc-modal"
      :buttons="[{ title: 'Dismiss', variant: 'btn-secondary' }]">
      <template #body>
        <div v-html="modalHTML" />
      </template>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import { set } from 'vue';
import BModal from '../components/common/BModal.vue';

export default {
  name: 'Calendar',
  components: {
    FullCalendar,
    BModal,
  },
  data() {
    return {
      calendarOptions: {
        initialView: 'listMonth',
        plugins: [dayGridPlugin, listPlugin],
        events: [],
        nextDayThreshold: '04:00:00',
        eventClick: this.eventClick,
        eventDidMount: this.eventRender,
        viewSkeletonRender: this.colorButtons,
        editable: false,
        droppable: false,
        eventStartEditable: 'false',
      },
      dbRef: null,
      clocation: '',
      dbContext: 'events-resort',
      isTabbed: '',
      startDate: '',
      endDate: '',
      duration: '',
      defaultHeight: '',
      colorAccent: '',
      colorBackground: '',
      timeZone: '',
      locations: {},
      views: {},
      modalHTML: null,
      modalTitle: null,
    };
  },
  computed: {
    getButtonStyle: function () {
      return { 'background-color': this.colorAccent, 'border-color': this.colorAccent };
    },
    getBackground: function () {
      return { 'background-color': this.colorBackground, 'border-color': this.colorBackground };
    },
  },
  async created() {
    this.views = {
      list: {
        validRange: {
          start: moment().format(),
        },
      },
    };
    this.clocation = this.$route.query.clocation || 'demo-campground';
    this.dbContext = this.$route.query.selectedTab || 'events-resort';
    this.calendarOptions.initialView = this.$route.query.defaultView || this.calendarOptions.initialView;
    this.isTabbed = this.$route.query.isTabbed;
    this.calendarOptions.defaultDate = this.$route.query.startDate || null;
    this.endDate = this.$route.query.endDate || null;
    this.calendarOptions.duration = this.$route.query.duration || null;
    this.calendarOptions.height = parseInt(this.$route.query.defaultHeight) - 16 || null;
    this.dbRef = firebase.database().ref('resort-navigator').child('location-data').child(this.clocation);
    this.dbRef
      .child('locations')
      .once('value')
      .then((snapshotLocations) => {
        this.locations = snapshotLocations.val();
      });
    this.calendarOptions.headerToolbar =
      this.dbContext == 'events-resort' ? { left: 'prev', right: 'next', center: 'title' } : { left: 'none', right: 'none', center: 'title' };
    let snapshot = await firebase.database().ref('resort-navigator').child('/campground-locations').child(this.clocation).once('value');
    this.colorAccent = this.$route.query.colorAccent ? '#' + this.$route.query.colorAccent : snapshot.child('colorAccent').val();
    this.colorBackground = this.$route.query.colorBackground ? '#' + this.$route.query.colorBackground : snapshot.child('colorBackground').val();
    this.colorPrimary = this.$route.query.colorPrimary ? '#' + this.$route.query.colorPrimary : snapshot.child('colorPrimary').val();
    this.colorText = this.$route.query.colorText ? '#' + this.$route.query.colorText : '#ffffff';

    this.timeZone = snapshot.child('timeZone').val();
    this.dbRef
      .child(this.dbContext.replace('.', '/'))
      .once('value')
      .then((eventSS) => {
        eventSS.forEach((element) => {
          var startDate = parseInt(element.key);
          var eventStartDate = moment.tz(startDate, this.timeZone).format();
          var eventEndDate = moment.tz(startDate + element.child('endDateDuration').val(), this.timeZone).format();
          const location = element.child('resortLocationId').val() ? element.child('resortLocationId').val() : element.child('location').val();
          const locationName = this.locations[location] && this.locations[location].title ? this.locations[location].title : location;

          this.calendarOptions.events.push({
            id: element.key,
            title: element.child('title').val(),
            start: eventStartDate,
            end: eventEndDate,
            description: element.child('description').val(),
            locationName: locationName,
            drawable: element.child('drawable').val(),
          });
        });
      });

    console.log(this.calendarOptions);
  },
  methods: {
    colorButtons: function () {
      this.$refs.fullCalendar.$el.querySelectorAll('.fc-button').forEach((item) => {
        item.style.background = this.colorAccent;
      });
      this.$refs.fullCalendar.$el.querySelectorAll('.fc-event').forEach((item) => {
        item.style.color = this.colorText;
      });
    },
    getDescription: function (event) {
      const startDate = moment.tz(event.start, this.timeZone).format('MMMM Do YYYY @ h:mm a');
      const endDate = moment.tz(event.end, this.timeZone).format('MMMM Do YYYY @ h:mm a');
      let html = event.extendedProps.drawable ? `<img class="event-image" src="${event.extendedProps.drawable}"/>` : '';
      html += `<div class="event-content"><span class="fw-bold pr-1">Start Time:</span><span class="event-value">${startDate}</span><br/>`;
      html += `<span class="fw-bold pr-1">End Time:</span><span class="event-value">${endDate}</span><br/>`;
      html += `<span class="fw-bold pr-1">Location:</span><span class="event-value">${event.extendedProps.locationName}</span><br/>`;
      html += `${event.extendedProps.description}</div>`;
      return html;
    },
    eventRender: function (info) {
      this.$refs.fullCalendar.$el.querySelector('.fc-toolbar-title').style.color = this.colorText;
      this.$refs.fullCalendar.$el.querySelector('.fc-scroller').style.backgroundColor = this.colorBackground;
      this.colorButtons();

      console.log('🚀 ~ file: Calendar.vue ~ line 179 ~ eventRender', info);
      if (info.view.type.startsWith('list')) {
        const widget = this.$refs.fullCalendar.$el.querySelectorAll('.fc-list-event');
        console.log('widget', widget);
        widget.forEach((item) => {
          item.style.color = this.colorText;
          item.style.backgroundColor = this.colorPrimary;
        });
        const title = info.el.querySelectorAll('.fc-list-event-title a')[0];
        title.innerText = `${title.innerText} @${info.event.extendedProps.locationName}`;
      }
    },
    eventClick: function (info) {
      if (info.view.type.startsWith('list')) {
        const tableEl = info.el.parentNode.parentNode;
        if (tableEl.rows[info.el.rowIndex + 1] && tableEl.rows[info.el.rowIndex + 1].className == 'event-description') {
          tableEl.deleteRow(info.el.rowIndex + 1);
        } else {
          const tableRow = tableEl.insertRow(info.el.rowIndex + 1);
          tableRow.style.color = this.colorText;
          // tableRow.style.backgroundColor = this.colorBackground;
          tableRow.className = 'event-description';
          const cell1 = tableRow.insertCell(0);
          cell1.colSpan = 3;
          cell1.innerHTML = this.getDescription(info.event);
        }
      } else {
        this.modalHTML = this.getDescription(info.event);
        this.modalTitle = info.event.title;
        this.$refs.modal.show();
      }
      //this.eventRender(info);
    },
    dismissModal: function () {
      this.$refs.modal.hide();
    },
  },
};
</script>

<style lang="scss">
// @import '~@fullcalendar/core/main.css';
// @import '~@fullcalendar/daygrid/main.css';
// @import '~@fullcalendar/list/main.css';
.event-content span {
  display: inline-block;
}
.event-image {
  margin: auto;
  display: block;
  max-width: 400px;
  width: 90%;
}
.event-label {
  font-weight: bold;
}
.event-value {
  padding-left: 5px;
  padding-bottom: 5px;
}
div#dialog-form .event-image {
  display: block;
  margin: auto;
  float: none;
  padding-bottom: 5px;
  max-width: 300px;
}

.fc-list-day-text,
.fc-list-day-side-text,
.fc-list-day-text:hover,
.fc-list-day-side-text:hover {
  text-decoration: none;
  color: #222222;
}

.fc-day-grid-event .fc-content {
  white-space: inherit;
  margin-bottom: 4px;
}
.fc-list-item-marker.fc-widget-content,
.fc-today-button.fc-state-disabled,
.fc-event-dot {
  display: none;
}
.fc-list-event,
.fc-event {
  cursor: pointer;
}
.fc-button {
  color: #ffffff;
  border: none;
  outline: 0;
}
</style>
