<template>
  <table class="w-100">
    <tr v-for="(day, key) in daysOfWeek" v-bind:key="key" class="day-row">
      <td>
        {{ day.name }}
      </td>
      <td>
        <table v-if="!daysClosed[key]">
          <tr v-for="(thisHour, index) in hoursModel[key]" v-bind:key="index">
            <td>
              <div class="input-group">
                <VueDatePicker
                  v-model="thisHour.open"
                  placeholder="Open Time"
                  format="h:mm aa"
                  time-picker
                  :is-24="false"
                  :teleport="true"
                  locale="en-US"
                  :aria-controls="`open${key}`"
                  @update:model-value="updateRow(key, index, 'open', $event)"
                  @cleared="updateRow(key, index, 'open', { hours: '?', minutes: '?' })" />
              </div>
            </td>
          </tr>
        </table>
      </td>
      <td>
        <table v-if="!daysClosed[key]">
          <tr v-for="(thisHour, index) in hoursModel[key]" v-bind:key="index">
            <td>
              <div class="input-group">
                <VueDatePicker
                  v-model="thisHour.close"
                  placeholder="Close Time"
                  format="h:mm aa"
                  time-picker
                  disable-time-range-validation
                  :is-24="false"
                  :teleport="true"
                  locale="en-US"
                  :aria-controls="`close${key}`"
                  @update:model-value="updateRow(key, index, 'close', $event)"
                  @cleared="updateRow(key, index, 'close', { hours: '?', minutes: '?' })" />
              </div>
            </td>
            <td class="additional-hours">
              <i title="Add Hours" class="material-icons" @click.stop="addRemoveRow(key, index)">{{ index == 0 ? 'add_circle' : 'remove_circle' }}</i>
            </td>
          </tr>
        </table>
      </td>
      <td v-if="displayClosed !== false">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" v-model="daysClosed[key]" id="closedCheckbox" />
          <label class="form-check-label" for="closedCheckbox"> Closed </label>
        </div>
      </td>
    </tr>
  </table>
</template>

<script>
import Vue from 'vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  components: { VueDatePicker },
  props: ['displayClosed'],
  emits: ['update:hours', 'update:text'],
  data() {
    return {
      //Used internally to hook up hours to the v-model of time-picker.
      hoursModel: {
        Mon: [{ open: { hours: '?', minutes: '?' }, close: { hours: '?', minutes: '?' } }],
        Tue: [{ open: { hours: '?', minutes: '?' }, close: { hours: '?', minutes: '?' } }],
        Wed: [{ open: { hours: '?', minutes: '?' }, close: { hours: '?', minutes: '?' } }],
        Thu: [{ open: { hours: '?', minutes: '?' }, close: { hours: '?', minutes: '?' } }],
        Fri: [{ open: { hours: '?', minutes: '?' }, close: { hours: '?', minutes: '?' } }],
        Sat: [{ open: { hours: '?', minutes: '?' }, close: { hours: '?', minutes: '?' } }],
        Sun: [{ open: { hours: '?', minutes: '?' }, close: { hours: '?', minutes: '?' } }],
      },
      //Used to emit the end result for the parent component's newItem.hours and to build hours text for display.
      // eslint-disable-next-line vue/no-reserved-keys
      _hours: {
        Mon: [{ open: '', close: '' }],
        Tue: [{ open: '', close: '' }],
        Wed: [{ open: '', close: '' }],
        Thu: [{ open: '', close: '' }],
        Fri: [{ open: '', close: '' }],
        Sat: [{ open: '', close: '' }],
        Sun: [{ open: '', close: '' }],
      },
      //Name map for days of the week.
      daysOfWeek: {
        Mon: { name: 'Monday' },
        Tue: { name: 'Tuesday' },
        Wed: { name: 'Wednesday' },
        Thu: { name: 'Thursday' },
        Fri: { name: 'Friday' },
        Sat: { name: 'Saturday' },
        Sun: { name: 'Sunday' },
      },
      daysClosed: {
        Mon: false,
        Tue: false,
        Wed: false,
        Thu: false,
        Fri: false,
        Sat: false,
        Sun: false,
      },
    };
  },
  watch: {
    hoursModel: {
      handler: function () {
        this.buildHours();
        this.buildText();
      },
      deep: true,
    },
    daysClosed: {
      handler: function () {
        this.buildHours();
        this.buildText();
      },
      deep: true,
    },
  },
  methods: {
    //Called by parent component *only once* after newItem.hours is loaded.
    //Parameter hours has to be passed here instead of as a prop, since the prop won't be updated before this method is called.
    load(hours) {
      this._hours = hours;
      this._convertFromProp();
    },

    updateRow: function (key, index, openclose, value) {
      if (value) this.hoursModel[key][index][openclose] = value;
    },
    addRemoveRow: function (key, index) {
      if (index == 0) {
        this.hoursModel[key].push({ open: { hours: '?', minutes: '?' }, close: { hours: '?', minutes: '?' } });
        this.updateRow(key, this.hoursModel[key].length - 1, 'open', { hours: '?', minutes: '?' });
        this.updateRow(key, this.hoursModel[key].length - 1, 'close', { hours: '?', minutes: '?' });
      } else {
        this.hoursModel[key].splice(index, 1);
      }
    },

    //Called on every change to internal hoursModel.
    buildHours() {
      const keys = Object.keys(this.hoursModel);
      for (const key of keys) {
        //Remove time if day is closed.
        if (this.daysClosed[key] && this._hours[key]) {
          this.$delete(this._hours, key);
        } else if (!this.daysClosed[key] && !this._hours[key]) {
          Vue.set(this._hours, key, [{ open: '', close: '' }]);
        }

        if (this.hoursModel[key] && !this.daysClosed[key]) {
          this._hours[key] = [];
          this.hoursModel[key].forEach((time, index) => {
            const openClose = Object.keys(time);
            this._hours[key].push({});
            openClose.forEach((val) => {
              if (time[val].hours == '?' || time[val].minutes == '?') {
                this._hours[key][index][val] = '?';
              } else {
                const date = new Date(`1970-01-01 ${time[val].hours}:${time[val].minutes}`);
                this._hours[key][index][val] = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
              }
            });
          });
        }
      }
      this.$emit('update:hours', this._hours);
    },
    buildText() {
      let startingDay = '';
      let endingDay = '';
      let tempHours = [];
      let sbDays = '';
      let sbHours = '';
      if (this._hours != null) {
        const keys = Object.keys(this.daysOfWeek);
        for (const key of keys) {
          let multiHours = false;
          if (startingDay == '') {
            startingDay = key;
            tempHours = this._hours[key];
          } else {
            if (JSON.stringify(tempHours) == JSON.stringify(this._hours[key])) {
              endingDay = key;
            } else {
              let questionableHours = false;
              if (tempHours == null) {
                sbHours += 'Closed<br/>';
              } else {
                let hoursStringList = null;
                for (const currentHours of tempHours) {
                  if (hoursStringList == null) {
                    hoursStringList = '';
                  } else {
                    hoursStringList += '<br/>';
                    multiHours = true;
                  }
                  if (['?', '', null].includes(currentHours.open) && ['?', '', null].includes(currentHours.close)) {
                    questionableHours = true;
                  } else {
                    hoursStringList += `${currentHours.open ? currentHours.open : ''} - ${currentHours.close ? currentHours.close : ''}`;
                  }
                }
                if (hoursStringList != null && !questionableHours) {
                  sbHours += `${hoursStringList}<br/>`;
                }
              }
              if (!questionableHours) {
                let dayText = startingDay;
                if (endingDay != '') {
                  dayText += ' - ' + endingDay;
                }
                if (!dayText.includes('-')) {
                  dayText = this.daysOfWeek[dayText].name;
                }

                sbDays += `${dayText}<br/>`;
                if (multiHours) {
                  sbDays += `<br/>`;
                }
              }
              startingDay = key;
              endingDay = '';
              tempHours = this._hours[key];
            }
          }
        }
        let questionableHours = false;
        let multiHours = false;
        if (tempHours == null) {
          sbHours += 'Closed';
        } else {
          let hoursStringList = null;
          for (const currentHours of tempHours) {
            if (hoursStringList == null) {
              hoursStringList = '';
            } else {
              hoursStringList += '<br/>';
              multiHours = true;
            }
            if (['?', '', null].includes(currentHours.open) && ['?', '', null].includes(currentHours.close)) {
              questionableHours = true;
            } else {
              hoursStringList += `${currentHours.open ? currentHours.open : ''} - ${currentHours.close ? currentHours.close : ''}`;
            }
          }
          if (hoursStringList != null && !questionableHours) {
            sbHours += hoursStringList;
          }
        }

        if (!questionableHours) {
          let dayText = startingDay;

          if (endingDay != '') dayText += ' - ' + endingDay;
          if (!dayText.includes('-')) dayText = this.daysOfWeek[dayText].name;

          sbDays += dayText;
          if (multiHours) sbDays += '<br/>';
        }

        let anyHours = false;
        for (const key of keys) {
          if (this._hours[key]) {
            for (const hour of this._hours[key]) {
              if (hour.open != '?' || hour.close != '?') {
                anyHours = true;
                break;
              }
            }
          }
          if (anyHours) break;
        }

        const obj = { hours: sbHours, days: sbDays, noHours: !sbDays && !anyHours };
        this.$emit('update:text', obj);
      }
    },

    //Called only once on component creation to convert hours prop to hoursModel.
    _convertFromProp: function () {
      if (this._hours) {
        const keys = Object.keys(this._hours);
        this.daysClosed = { Mon: true, Tue: true, Wed: true, Thu: true, Fri: true, Sat: true, Sun: true };

        for (const key of keys) {
          if (this._hours[key]) {
            this.daysClosed[key] = false;

            //Populate additional empty hours
            const addCount = this._hours[key].length - this.hoursModel[key].length;
            for (let index = 0; index < addCount; index++) {
              this.hoursModel[key].push({ open: { hours: '?', minutes: '?' }, close: { hours: '?', minutes: '?' } });
            }

            this._hours[key].forEach((time, index) => {
              const openClose = Object.keys(time);

              openClose.forEach((val) => {
                if (time[val] == '?') return;

                const date = new Date(`1970-01-01 ${time[val]}`);
                const hours = date.getHours();
                const minutes = date.getMinutes();

                //Return early if day:index:openClose is not set
                if (hours == '?' || minutes == '?' || isNaN(hours) || isNaN(minutes)) return;

                //Otherwise, set internal v-model to hours prop, converted to hours/minutes object.
                this.hoursModel[key][index][val] = { hours: date.getHours(), minutes: date.getMinutes() };
              });
            });
          }
        }
      }
    },
    convertFromLocation: function (hours) {
      for (let day in hours) {
        for (let index in hours[day]) {
          this.hoursModel[day][index] = this.convertOpenClose(hours[day][index]);
        }
      }
    },
    convertOpenClose(obj) {
      if (obj.hasOwnProperty('open') && obj.hasOwnProperty('close')) {
        const open = this.convertToInner(obj.open);
        const close = this.convertToInner(obj.close);
        return { open, close };
      }
    },
    convertToInner(timestring) {
      const split = timestring.split(' ');
      const builtTime = `${split[0]}:00 ${split[1]}`;

      const date = new Date(`01-01-1970 ${builtTime}`);
      const hours = date.getHours();
      const minutes = date.getMinutes();
      return { hours, minutes };
    },
  },
};
</script>

<style lang="scss">
tr.day-row {
  padding: 0px 8px;
  border: 1px solid #18344980;
  height: 45px;
}
tr.day-row > td {
  padding: 4px 1px;
}

.additional-hours {
  line-height: 1rem;
  vertical-align: middle;
  color: #183449;
  cursor: pointer;
}

.field.sm .field-input {
  margin-bottom: 0px;
}
</style>
